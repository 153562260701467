<template>
  <v-row style="min-height: 100vh">
    <v-col class="white" cols="12" md="8" xl="9">
      <v-row justify="center" style="height: 100%;">
        <v-col class="px-4 py-16 d-flex flex-column" cols="12">
          <v-row justify="center">
            <v-col cols="12" xl="4" lg="6" md="8" sm="10">
              <div class="mb-16">
                <v-img :src="LOGO" height="32" contain position="left"></v-img>
              </div>
              <div class="mb-8 text-h4">
                Registrasi
              </div>
              <div class="mb-16">
                <v-form
                  :disabled="loading"
                  ref="registerForm"
                  lazy-validation
                  @submit.prevent="handleRegister"
                >
                  <div class="mb-4">
                    <div class="mb-2 font-weight-bold">Alamat Email</div>
                    <v-text-field
                      v-model="email"
                      :rules="emailRequired"
                      type="email"
                    >
                    </v-text-field>
                  </div>
                  <div class="mb-4">
                    <div class="mb-2 font-weight-bold">Kata Sandi</div>
                    <v-text-field
                      v-model="password"
                      :rules="passwordRequired"
                      :type="isShowPassword ? 'text' : 'password'"
                    >
                      <template #append>
                        <v-btn
                          icon
                          @click="isShowPassword = !isShowPassword"
                          style="margin-top: -12px;"
                        >
                          <v-icon>{{
                            isShowPassword ? "ri-eye-fill" : "ri-eye-off-fill"
                          }}</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                  <!-- <vue-recaptcha
                    ref="recaptchaRefs"
                    :sitekey="sitekey"
                    @verify="verifyMethod"
                    @expired="expiredMethod">
                  </vue-recaptcha> -->
                  <div class="my-4">
                    <v-btn
                      color="primary"
                      :loading="loading"
                      large
                      block
                      type="submit"
                      >Registrasi</v-btn
                    >
                  </div>

                  <div class="mb-4 text-center">
                    Sudah punya akun Pensign?
                  </div>

                  <div class="mb-4">
                    <v-btn
                      :disabled="loading"
                      outlined
                      large
                      block
                      @click="$router.push({ name: 'login' })"
                      >Masuk</v-btn
                    >
                  </div>
                </v-form>
                <div>
                  Dengan klik <span class="font-weight-bold">Registrasi</span>,
                  Anda menyetujui
                  <a
                    href="https://pensign.id/privacy-policy"
                    target="_blank"
                    class="primary--text"
                    >Kebijakan Privasi</a
                  >
                  dan
                  <a
                    href="https://pensign.id/term-and-condition"
                    target="_blank"
                    class="primary--text"
                    >Syarat & Ketentuan</a
                  >
                  Pensign.
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12 mt-auto">
          <div class="mt-auto mb-4">
            &copy; 2024, Pensign. Semua Hak Dilindungi
            Undang-Undang.
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" md="4" xl="3">
      <v-row class="h-full" align="center">
        <m-auth-carousel></m-auth-carousel>
      </v-row>
    </v-col>

    <v-dialog
      v-model="showFeedback"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-row class="white" style="height: 100%">
        <v-col class="py-16">
          <div class="mb-16 text-center">
            <v-img :src="LOGO" height="32" contain></v-img>
          </div>
          <div class="mb-2 text-center text-h5">
            Periksa Email Anda
          </div>
          <div class="mb-2 text-center">
            Kami telah mengirim tautan konfirmasi kepada:
          </div>
          <div class="mb-8 text-center primary--text">{{ email }}</div>
          <div class="text-center">
            Periksa email anda dan ikuti instruksi<br />
            untuk melanjutkan proses registrasi
          </div>
        </v-col>
      </v-row>
    </v-dialog>

    <v-snackbar v-model="showAlert" top text color="error">
      {{ alertMessage }}
    </v-snackbar>
  </v-row>
</template>

<script>
import LOGO from "@/assets/images/logo.png";
import * as newAuth from "@/model/new-auth";
import mAuthCarousel from '../../components/m-auth-carousel.vue';
// import { VueRecaptcha } from 'vue-recaptcha';

export default {
  components: { 
    mAuthCarousel,
    // VueRecaptcha
  },
  data: () => ({
    sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
    active: null,
    email: null,
    password: null,
    captcha: "",
    isShowPassword: false,
    showFeedback: false,
    loading: false,
    showAlert: false,
    alertMessage: "",
  }),
  computed: {
    LOGO: () => LOGO,
    emailRequired: () => [
      (v) => !!v || "Email tidak boleh kosong",
      (v) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || "Email tidak valid.";
      },
    ],
    passwordRequired: () => [
      (v) => !!v || "Kata sandi tidak boleh kosong",
      (v) => {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return pattern.test(v) || "Password harus memiliki setidaknya 8 karakter dan memiliki 1 huruf besar, 1 huruf kecil, 1 simbol, serta 1 angka.";
      },
    ],
  },
  mounted() {
    document.title = "Registrasi - " + process.env.VUE_APP_TITLE;
  },
  methods: {
    // verifyMethod(token){
    //   this.captcha = token
    // },

    // expiredMethod() {
    //   this.captcha = ''
    // },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')

      return token

      // Do stuff with the received token.
    },
    async handleRegister() {
      const isValid = await this.$refs.registerForm.validate();
      if (isValid) {
        this.loading = true;
        const captcha = await this.recaptcha();

        newAuth
          .register({ email: this.email, password: this.password, captcha })
          .then(() => {
            this.showFeedback = true;
          })
          .catch((e) => {
            let msg = e.message;
            if (e.response?.data?.message) msg = e.response?.data?.message;
            this.showAlert = true;
            this.alertMessage = msg;

            this.$refs.recaptchaRefs.reset();
          })
          .finally(() => {
            this.loading = false;
          });
      }else {
        this.showAlert = true;
        this.alertMessage = "Mohon isi captcha";
      }
    },
  },
};
</script>
