const url_v1 = "v1/"
export default {
    admin_login: 'app/dashboard/sign-in',
    continue_authenticator: 'app/dashboard/sign-in/authorize',
    setup_authenticator: 'app/dashboard/mfa/authenticator',
    // admin_login: 'auth/dashboard/login',
    admin_detail: `${url_v1}auth/users/profile`,
    certificate_list: `${url_v1}certificate/certificate`,
    recipient_list: `${url_v1}certificate/certificate/recipient/`,
    recipient_all: `${url_v1}certificate/recipient/list`,
    recipient_create: `${url_v1}certificate/recipient/create`,
    recipient_delete: `${url_v1}certificate/recipient/delete`,
    publish_certificate: `${url_v1}certificate/publish`,
    download_example_excel: `${url_v1}certificate/recipient/download_excel`,
    design_request: `${url_v1}certificate/design/upload_design`,
    certificate_template: `${url_v1}certificate/template/`,
    design_list: `${url_v1}certificate/design`,
    template_create: `${url_v1}certificate/template/create/`,
    template_edit: `${url_v1}certificate/template/edit/`,
    template_list: `${url_v1}certificate/template/`,
    template_detail: `${url_v1}certificate/template/`,
    cridetial_list: `${url_v1}certificate/credential`,
    cridetial_detail: `${url_v1}certificate/credential/`,
    certificate_templates: `${url_v1}certificate/template`,
    recipient_edit: `${url_v1}certificate/recipient/edit`,
    recipient_import: `${url_v1}certificate/recipient/import-excel`,
    recipient_list_not_publish: `${url_v1}certificate/recipient/list/`,

    get_bank_name: `${url_v1}etransfer/bank`,
    get_bank_account: `${url_v1}etransfer/bank_account`,
    // Deposit
    get_deposit_balance: `${url_v1}etransfer/balance`,
    get_company_bank: `${url_v1}etransfer/bank/destination`,
    request_deposit: `${url_v1}etransfer/balance/deposit`,
    request_deposit_confirmation: `${url_v1}etransfer/balance/deposit-confirmation`,
    // Invoice
    list_invoice: `${url_v1}etransfer/invoice?`,
    create_invoice: `${url_v1}etransfer/invoice/create`,
    // Payment Link
    list_payment_link: `${url_v1}etransfer/payment_link?`,
    create_payment: `${url_v1}etransfer/payment_link/create`,
    // Disbursemenet
    list_disbursement: `${url_v1}etransfer/disbursement/list?`,
    summary_transaction_disbursement: `${url_v1}etransfer/disbursement/summary`,
    // Bulk Disbursemenet
    list_bulk_disbursement: `${url_v1}etransfer/disbursement/bulk/list?`,
    validate_need_validation: `${url_v1}etransfer/disbursement/validate`,
    validate_need_approval: `${url_v1}etransfer/disbursement/approval`,
    // Rilis Disbursemenet
    approve_disbursement: `${url_v1}etransfer/disbursement/approve`,
    release_code: `${url_v1}etransfer/disbursement/release_code`,
    release_disbursement: `${url_v1}etransfer/disbursement/release`,
    // Schedule Disbursemenet
    list_schedule_disbursement: `${url_v1}etransfer/schedule?`,
    create_schedule: `${url_v1}etransfer/schedule/create`,
    // Transaction History
    list_transaction_history: `${url_v1}etransfer/transaction?`,
    balance_transaction_history: `${url_v1}etransfer/balance`,
    // Incoming Transfer
    list_incoming_transfer: `${url_v1}etransfer/transaction?type=in`,
    // Pembelian Product
    all_inventory: `${url_v1}econtract/inventory/all`,
    create_order: `${url_v1}econtract/inventory/client/orde`
}