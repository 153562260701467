<template>
  <div id="subscription-package">
    <v-card
      outlined
      class="ma-3"
      style="border-radius:6px;"
      data-v-step="2"
      :disabled="!enabledAuthenticator"
    >
      <img
        src="@/assets/images/logo.png"
        style="max-width: 150px;"
      />
      <v-card-title class="pt-0">
        {{ dataCount.name }}
        <v-spacer />
        <v-chip label small>
          Max {{ dataCount.max_user }} Pengguna
        </v-chip>
      </v-card-title>
      <v-card-text>
        <v-row align="center">
          <v-col class="text-subtitle-1" cols="6">
            Pengguna
          </v-col>
          <v-col class="text-right text-subtitle-1" cols="6">
            {{ dataCount.total_user }}/{{ dataCount.max_user }}
          </v-col>
        </v-row>
        <v-progress-linear
          color="orange darken-2"
          rounded
          v-model="percentCount"
        ></v-progress-linear>
        <v-btn
          outlined
          block
          class="mt-4"
          style="border-radius:6px;"
          to="/dashboard#package"
        >
          <span style="color:#CD7B2E;">
            <v-icon left>ri-vip-crown-line</v-icon>
            Tingkatkan
          </span>
        </v-btn>
        <v-btn
          text
          block
          class="mt-4"
          style="border-radius:6px;"
          to="/history-invoice"
        >
          <span style="color:#475569;">
            Riwayat Paket
          </span>
        </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataCount: {},
      intervalTimer: null,
      intervalTimerTime: 30000
    };
  },
  computed: {
    enabledAuthenticator () {
      return this.$store.state.authenticator.enabledAuthenticator;
    },
    percentCount() {
      const a = (this.dataCount.total_user / this.dataCount.max_user) * 100;
      return a;
    },
  },
  unmounted() {
    clearInterval(this.intervalTimer);
  },
  mounted() {
    this.getCounter();
    this.intervalTimer = setInterval(() => {
      const pensignAuth = localStorage.getItem("pensign_auth");
      if(pensignAuth) {
        this.getCounter();
      }
    }, this.intervalTimerTime);
  },
  methods: {
    getCounter() {
      this.axios.get("v1/subscription/count").then((response) => {
        let res = response.data.data;
        this.dataCount = res;
      });
    },
  }
}
</script>