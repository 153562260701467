<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="px-0 pt-0 pb-0 text-black fs-20 font-weight-bold"
      >Beli {{ dataDetail.product_name }}</v-card-title
    >

    <v-row>
      <v-col cols="8">
        <v-card-text class="px-0">
          <v-form ref="form" @submit.prevent="submit" v-model="valid">
            <v-card elevation="1">
              <div class="px-3 py-3">
                <v-row class="px-3 py-4">
                  <v-col cols="12">
                    <div class="d-flex" style="justify-content: space-between;align-items: center;">
                      <div class="d-flex">
                        <div class="mr-5">
                          <img
                            src="/assets/emet.png"
                            alt=""
                            draggable="false"
                          />
                          <img
                            src="/assets/api-seeting.png"
                            alt=""
                            style="width:30px;position: absolute;left: 46px;top: 50px;"
                            draggable="false"
                          />
                        </div>
                        <div>
                          <p class="mb-0 text-black font-weight-bold fs-14">
                            {{ dataDetail && dataDetail.product_name }}
                          </p>
                          <div>
                            <p class="mb-0 text-black font-weight-thin fs-14">
                              <span v-for="(detail, index) in dataDetail.product_item" :key="index">
                                {{ detail.product_qty }} {{ detail.product.name }}<span v-if="index > 1">,</span>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h3 class="text-black">
                          Rp. {{ formatPrice(dataDetail && dataDetail.total_price) }}
                        </h3>
                      </div>
                    </div>
                  </v-col>
                </v-row>

              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3">
                <v-row class="px-3 py-2">
                  <v-col cols="12">
                    <div class="pricing-card-counter-price">
                      <div class="counter-price">
                        <p class="mb-0 text-black font-weight-thin">Quantity</p>
                        <v-btn-toggle>
                          <v-btn @click="addToCart()">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 4v16m8-8H4"
                              />
                            </svg>
                          </v-btn>

                          <v-btn @click="reduceToCart()" :disabled="dataDetail.product_qty === 0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="w-6 h-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M20 12H4"
                              />
                            </svg>
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                      <div>
                        <h2 class="mr-2 text-black">
                          {{ dataDetail && dataDetail.product_qty }}
                        </h2>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3">
                <v-row class="px-3 py-2">
                  <v-col cols="6">
                    <p class="mb-0 text-black font-weight-thin">Sub-Total</p>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex align-center justify-content-end"
                  >
                    <div class="d-flex">
                      <h2 class="mr-2 text-black">
                        Rp. {{ formatPrice(dataDetail.product_qty * dataDetail.price) }}
                      </h2>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3">
                <v-row class="px-3 py-2">
                  <v-col cols="6">
                    <p class="mb-0 text-black font-weight-thin">Voucher</p>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex align-center justify-content-end"
                  >
                    <div class="d-flex">
                      <h2 class="mr-2 text-black">
                        0
                      </h2>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3">
                <v-row class="px-3 py-2">
                  <v-col cols="6">
                    <p class="mb-0 text-black font-weight-bold">Total Tagihan</p>
                  </v-col>
                  <v-col
                    cols="6"
                    class="d-flex align-center justify-content-end"
                  >
                    <div class="d-flex">
                      <h2 class="mr-2 text-black">
                        Rp. {{ formatPrice(dataDetail.product_qty * dataDetail.price) }}
                      </h2>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <div class="px-3 py-3">
                <v-row class="px-3 py-4">
                  <v-col cols="12">
                    <p class="mb-0 text-black font-weight-thin">
                      You have the option to process the transaction with your
                      balance or with a direct bank transfer. If your balance is
                      insufficient, your transaction will be completed by bank
                      transfer.
                    </p>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider>

              <v-card-actions>
                <div class="row">
                  <div class="px-0 text-right col-sm-12">
                    <v-btn
                      color="primary"
                      outlined
                      class="px-5 mr-2"
                      @click="cancelTransfer()"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="primary"
                      dark
                      class="px-3"
                      :loading="submiting"
                      @click="confirmTransfer()"
                    >
                      Continue
                    </v-btn>
                      <!-- :to="{name: 'payment-method'}" -->
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-form>

          <v-snackbar v-model="isError">
            {{ showMessage }}
            <template v-slot:action="{attrs}">
              <v-btn color="pink" text v-bind="attrs" @click="isError = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card-text>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
// import {reactive, toRefs, onMounted} from "@vue/composition-api";
import axios from "axios";
import {formatPrice} from "@/lib/formatDate";

export default {
  // setup(props, ctx) {
  //   const route = ctx.root._route;
  //   const state = reactive({
  //     pricing: null,
  //     isLoading: true,
  //   });

  //   onMounted(async () => {
  //     state.isLoading = true;
  //     let {data: data} = await axios.get(
  //       "https://pensign.herokuapp.com/v1/e-signature/pricing/" + route.params.id
  //     );
  //     state.pricing = data.data;
  //     state.isLoading = false;
  //   });

  //   return {
  //     ...toRefs(state),
  //   };
  // },

  data: () => ({
    valid: true,
    isError: false,
    isLoading: false,
    submiting: false,
    showMessage: "",
    disabled: false,
    dataDetail: {},
    serverParams: {
      order_id: "",
      product_id: "",
      qty: "",
    },
    selectedItem: {},
  }),
  mounted() {
    this.getListDetailPaket();
  },
  watch: {
    code() {
      if(this.code.length === 6 ) this.handleSubmit()
    }
  },
  computed: {
    idParam: {
      get() {
        return this.$route.params.id;
      },
    },
  },
  methods: {
    getListDetailPaket() {
      return new Promise((resolve, reject) => {
          axios
          .get(`v1/econtract/inventory/client/order?order_id=${this.idParam}` )
          .then(response => {
            const { data } = response.data;
            this.dataDetail = data;
            resolve(response);
          })
          .catch(error => {
              reject(error);
          });
      });
    },

    addToCart() {
      this.dataDetail.product_qty += 1;
      this.dataDetail.product_price = this.dataDetail.product_price * this.dataDetail.product_qty;
    },

    reduceToCart() {
      this.dataDetail.product_qty -= 1;
    },

    async confirmTransfer() {
      this.serverParams.order_id = this.dataDetail.id;
      this.serverParams.product_id = this.dataDetail.product_id;
      this.serverParams.qty = this.dataDetail.product_qty;
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          this.axios
          .post(
              `v1/econtract/inventory/client/order/checkout`, this.serverParams,
              { headers: {"Content-Type": "application/json"} })
          .then((response) => {
              this.isError = true;
              this.showMessage = response.data.msg
              this.submiting = false;
              setTimeout( () => {
                  this.$router.push({ name: 'payment-method', params: { id: this.serverParams.order_id} })
              }, 1500);
          })
          .catch((error) => {
              this.submiting = false;
              this.isError = false;
              this.showMessage = error.response.data.msg;
          });
        }
      }, 50);
    },
    async cancelTransfer() {
      this.serverParams.order_id = this.dataDetail.id;
      this.serverParams.product_id = this.dataDetail.product_id;
      this.serverParams.qty = this.dataDetail.product_qty;
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          this.submiting = true;
          this.axios
          .post(
              `v1/econtract/inventory/client/order/cancel`, this.serverParams,
              { headers: {"Content-Type": "application/json"} })
          .then((response) => {
              this.isError = true;
              this.showMessage = response.data.msg
              this.submiting = false;
              setTimeout( () => {
                  this.$router.push({ path: '/inventory/products' })
              }, 1500);
          })
          .catch((error) => {
              this.submiting = false;
              this.isError = false;
              this.showMessage = error.response.data.msg;
          });
        }
      }, 50);
    },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style scoped lang="scss">
.pricing {
  &-card {
    border-radius: 12px;
    padding: 20px;
    border: solid 1px #e0e0e0 !important;
    &-counter-price {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.counter-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}
.bg-grey {
  background-color: #f9f9f9;
}
.v-radio.type_fund.v-radio--is-disabled {
  border-color: #e0e0e0;
  background-color: #bdbdbd;
  color: #eeeeee !important;
}
.v-radio.type_fund.v-item--active {
  border-color: #0068d6;
  background-color: #0068d6;
  color: #fff !important;
}
.v-radio.type_fund:hover {
  border-color: #0159b6;
  background-color: #0159b6;
  color: #fff !important;
}

.v-radio.type_fund {
  padding: 10px 20px 10px 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
}
.list-paket {
  list-style: none;
  padding-left: 0;
}
.list-paket li {
  margin-bottom: 5px;
}
</style>
