<template>
  <v-row>
    <v-col cols="12">
      <div class="mb-4 text-black fs-20 font-weight-bold">
        Kelola API
      </div>
      <div class="mb-4">
        Kredensial berikut akan memungkinkan Anda mengautentikasi permintaan
        API.
      </div>
    </v-col>
    <v-col cols="12" md="6">
      <div class="mb-4 text-black font-weight-bold">
        API Secret Key
      </div>
      <v-card outlined class="pa-4">
        <div class="d-flex justify-space-between">
          <div class="d-flex align-center">
            <div v-if="showKey" class="text-wrap" style="word-wrap: anywhere">
              {{ api_key.secretKey.token }}
            </div>
            <div v-else>*********************</div>
          </div>

          <div class="d-flex flex-nowrap align-center">
            <v-btn icon class="ml-2" @click="showKey = !showKey">
              <v-icon v-if="!showKey" size="20">ri-eye-line</v-icon>
              <v-icon v-else size="20">ri-eye-off-line</v-icon>
            </v-btn>
            <v-btn class="" color="primary" text @click="handleClickCopyKey">
              <span class="mr-2">Salin</span>
              <v-icon>ri-file-copy-line</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card outlined class="h-full pa-4">
        <v-row>
          <v-col cols="12" md="6">
            <v-img src="@/assets/images/img-script.png" />
          </v-col>
          <v-col cols="12" md="6" class="d-flex flex-column">
            <div class="mb-4 text-black font-weight-bold">
              Jelajahi integrasi API dalam Test Mode
            </div>
            <v-divider class="mb-4" />
            <div>
              Nikmati akses mudah ke semua API Pensign di sini, membantu Anda
              menyelesaikan pekerjaan dengan lebih cepat dan efektif.
            </div>
            <div class="mt-auto">
              <a
                href="https://docs.pensign.id"
                target="_blank"
                class="font-weight-bold"
              >
                <v-icon size="16" color="primary" style="margin-bottom: 2px;"
                  >ri-terminal-box-line</v-icon
                >
                Cek Dokumentasi API di sini
                <v-icon size="16" color="primary" style="margin-bottom: 2px;"
                  >ri-arrow-right-s-line</v-icon
                >
              </a>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-snackbar v-model="showAlert" top text color="success">
      <v-icon color="success">ri-check-line</v-icon>
      {{ alertMessage }}
    </v-snackbar>
  </v-row>
</template>

<script>
import Axios from "axios";
export default {
  data() {
    return {
      api_key: {},
      showKey: false,
      alertMessage: "",
      showAlert: false,
    };
  },
  mounted() {
    this.getApiKey();
  },
  methods: {
    async getApiKey() {
      const res = await Axios.get("v1/auth/dashboard/api_key").then(
        (res) => res.data
      );
      console.log(res);
      this.api_key = res.data;
    },
    handleClickCopyKey() {
      navigator.clipboard.writeText(this.api_key.secretKey.token).then(() => {
        this.showAlert = true;
        this.alertMessage = "Secret key berhasil disalin.";
      });
    },
  },
};
</script>
