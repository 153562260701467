<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="px-0 pt-0 text-black fs-20 font-weight-bold">
      Riwayat Pembelian
    </v-card-title>

    <section class="content">
      <v-card class="px-5">
        <div class="content-header row">
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <v-select
                v-model="status"
                :items="availableFilter.status || []"
                item-value="value"
                solo
                flat
                item-text="label"
                @change="handleStatusChanged"
                placeholder="Pilih Status"
                outlined
              ></v-select>
            </div>
            <div class="col-lg-3 col-md-12">
              <v-menu
                v-model="isDatePickerShow"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    append-icon="ri-calendar-line"
                    solo
                    flat
                    clearable
                    readonly
                    hide-details="auto"
                    placeholder="Pilih Rentang Tanggal"
                    v-bind="attrs"
                    v-on="on"
                    v-model="dateRangeText"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  range
                  style="width: 100%"
                  @change="handleDateChanged"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-lg-3 col-md-12">
              <v-text-field
                outlined
                append-icon="$search"
                class=""
                placeholder="Cari No. Invoice"
                solo
                v-model="search"
                flat
                @keyup="handleSearchChanged"
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="text-right col-lg-3 col-md-12">
              <v-btn
                class="mr-2"
                color="primary"
                outlined
                @click="handleClickResetFilter"
                style="min-height: 39px"
              >
                Reset
              </v-btn>
              <v-btn
                color="grey darken-4"
                class="white--text"
                @click="getExport()"
                :loading="submitting"
                style="min-height: 39px"
              >
                <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Export
              </v-btn>
            </div>
          </div>
        </div>

        <v-data-table
          class="mt-5"
          :headers="headers"
          :items="rowData"
          :loading="isLoading"
          :items-per-page="limit"
          :page="page"
          :server-items-length="paginationData.total_data || 0"
          @update:items-per-page="handleLimitChange"
          @update:page="handlePageChanged"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              class="my-3 capitalize"
              :color="getStatusColor(item.status)"
              small
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:[`item.product`]="{ item }">
            <div class="py-3 d-flex">
              <!-- <div class="justify-center mr-3 d-flex align-center">
                <img
                  v-if="item.product == 'e-Meterai'"
                  style="width: 56px"
                  src="@/assets/images/emet.png"
                  alt=""
                />
                <img
                  v-else-if="item.product == 'e-Signature'"
                  style="width: 56px"
                  src="@/assets/images/peruri-logo.png"
                  alt=""
                />
                <img
                  v-else-if="item.product == 'KYC Privy'"
                  style="width: 56px"
                  src="@/assets/images/privy-kyc.png"
                  alt=""
                />
                <img
                  v-else-if="item.product == 'KYC Peruri'"
                  style="width: 56px"
                  src="@/assets/images/peruri-kyc.png"
                  alt=""
                />
                <img
                  v-else
                  style="width: 56px"
                  src="@/assets/images/privy-logo.png"
                  alt=""
                />
              </div> -->
              <div>
                <h4 v-if="item.product === 'e-Meterai'">EMET</h4>
                <h4 v-else-if="item.product == 'e-Signature'">ESGN</h4>
                <h4 v-else>{{ item.product }}</h4>

                <!-- <p>{{ item.productDescription }}</p> -->
                <div class="" style="border-top: 0px">
                  <!-- v-for="(facilities, index) in item.productDescription" -->
                  <div
                    v-for="(facilities, index) in getJson(
                      item.productDescription
                    )"
                    :key="'A' + index"
                    class=""
                  >
                    {{ facilities }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:[`item.payment`]="{ item }">
            <div class="capitalize">{{ item.payment }}</div>
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <div>
              <p>Rp. {{ formatToPrice(item.amount) }}</p>
            </div>
          </template>
          <template v-slot:[`item.invoiceId`]="{ item }">
            <div>
              <!-- <a :href="formatParson(item.invoiceId)" target="_blank">{{item.invoiceId}}</a> -->
              <a :href="item.invoice_url" target="_blank">{{
                item.invoiceId
              }}</a>
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <!-- <a :href="{ name: 'detail-paket', params: {id: item.id} }" target="_blank">invoice</a>                 -->
            <router-link
              :to="{ name: 'detail-purchase', params: { id: item.id } }"
              class="text-underline"
              >Invoice</router-link
            >
          </template>
        </v-data-table>
      </v-card>
      <v-snackbar
        top
        v-model="snackbar.state"
        :color="snackbar.status || 'primary'"
      >
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn small icon v-bind="attrs" @click="snackbar.state = false">
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </section>
  </v-card>
</template>

<script>
import {
  onMounted,
  computed,
  reactive,
  toRefs,
  ref,
} from '@vue/composition-api';
import axios from 'axios';
import { formatPrice } from '../../../lib/formatDate';
import { formatDate } from '../../../lib/formatDate';

import Vue from 'vue';
import router from '../../../router';

const headers = [
  {
    text: 'TANGGAL',
    align: 'start',
    sortable: false,
    value: 'date',
  },
  { text: 'NO. INVOICE', value: 'invoiceId' },
  { text: 'DESKRIPSI', value: 'product' },
  { text: 'QTY', value: 'qty' },
  { text: 'TOTAL', value: 'amount' },
  { text: 'SUMBER DANA', value: 'payment' },
  { text: 'STATUS', value: 'status' },
  { text: '', value: 'action' },
];

const states = {
  dates: [],
  statuses: [],
  status: '',
  search: '',
  menu: false,
  balance: 0,
  totalPoints: [],
  datas: [],
  isLoading: false,
  limit: 5,
  page: 1,
  total_data: null,
  filter: 'all',
};

const formatToDate = (value) => {
  return formatDate(value);
};

const API_ORDER_LOG = 'v1/econtract/inventory/client/purchase_v2';

export default {
  setup(props, context) {
    const dateRangeText = computed(() => {
      return state.dates.join(' ~ ');
    });

    const formatToPrice = (value) => {
      return formatPrice(value);
    };
    const route = context.root.$route;

    const state = reactive(states);
    const pageData = ref({});
    const isDatePickerShow = ref(false);
    const snackbar = ref({
      state: false,
      text: '',
      status: 'info',
    });

    const rowData = computed(() => {
      return pageData.value.purchase ?? [];
    });

    const availableFilter = computed(() => {
      return pageData.value.filter ?? {};
    });

    const paginationData = computed(() => {
      return pageData.value?.pagination || {};
    });

    const filterStatus = async () => {
      // console.log('status',state.status)

      // if total data is null, get total data first
      let limit = state.limit;
      if (limit == -1) {
        if (paginationData.value.total_data)
          limit = paginationData.value.total_data;
        else {
          const {
            data: {
              data: {
                pagination: { total_data },
              },
            },
          } = await axios.get(API_ORDER_LOG, {
            params: {
              date: state.dates.map((v) => v).join(','),
              status: state.status,
              search: state.search,
              product: 'all',
              limit: 1,
              page: 1,
            },
          });
          limit = total_data;
        }
      } else {
        limit = state.limit;
      }
      pageData.value = {};
      try {
        const { data: response } = await axios.get(API_ORDER_LOG, {
          params: {
            date: state.dates.map((v) => v).join(','),
            status: state.status,
            search: state.search,
            product: route.query.product,
            limit: limit,
            page: state.page,
          },
        });

        pageData.value = response.data;
      } catch (error) {
        snackbar.value.state = true;
        snackbar.value.text = error.message;
        snackbar.value.status = 'error';
      }

      state.isLoading = false;
    };

    function updateUrl(query) {
      const route = router.currentRoute;
      const url = new URL(location.origin + route.path);
      Object.keys(query).forEach((key) => {
        url.searchParams.append(key, query[key]);
      });

      history.pushState({}, null, url.toString());
      filterStatus();
    }

    function handleLimitChange(limit) {
      state.limit = limit;
      const route = router.currentRoute;

      if (route.query.limit != limit) {
        route.query.limit = limit;
        updateUrl(route.query);
      }
    }

    function handlePageChanged(page) {
      state.page = page;
      const route = router.currentRoute;
      if (route.query.page != page) {
        route.query.page = page;
        updateUrl(route.query);
      }
    }

    function handleProductChanged(product) {
      const route = router.currentRoute;
      if (route.query.product != product) {
        route.query.product = product;
        updateUrl(route.query);
      }
    }

    function handleStatusChanged(status) {
      const route = router.currentRoute;
      if (route.query.status != status) {
        route.query.status = status;
        updateUrl(route.query);
      }
    }

    function handleDateChanged(date) {
      const route = router.currentRoute;
      if (route.query.date != date) {
        route.query.date = date;
        updateUrl(route.query);
      }
    }

    const searchTimeout = ref();
    function handleSearchChanged(e) {
      const search = e.target.value;
      const route = router.currentRoute;
      clearTimeout(searchTimeout.value);
      searchTimeout.value = undefined;
      searchTimeout.value = setTimeout(() => {
        if (route.query.search != search) {
          route.query.search = search;
          updateUrl(route.query);
        }
      }, 300);
    }

    function getStatusColor(status) {
      switch ((status || '').toLowerCase()) {
        case 'paid':
          return 'success';
        case 'expired':
          return 'warning';
        default:
          return 'info';
      }
    }

    function handleClickResetFilter() {
      const route = router.currentRoute;
      Object.keys(route.query).forEach((key) => {
        delete route.query[key];
      });
      state.dates = [];
      state.status = '';
      state.search = undefined;
      state.limit = 5;
      state.page = 1;

      updateUrl({});
    }

    onMounted(async () => {
      // state.limit = 1000;
      state.page = parseInt(router.currentRoute.query.page) || 1;
      state.limit = parseInt(router.currentRoute.query.limit) || 5;
      state.status = router.currentRoute.query.status;
      state.dates = router.currentRoute.query.date
        ? router.currentRoute.query.date.split(',')
        : [];
      state.search = router.currentRoute.query.search || '';
      // if (!route.query.product) router.replace({ query: { product: 'all' } });
      filterStatus();
    });

    return {
      snackbar,
      searchTimeout,
      formatToPrice,
      formatToDate,
      isDatePickerShow,
      ...toRefs(state),
      headers,
      dateRangeText,
      filterStatus,
      pageData,
      paginationData,
      rowData,
      availableFilter,
      // formatParson,
      getStatusColor,
      handleStatusChanged,
      handleLimitChange,
      handlePageChanged,
      handleDateChanged,
      handleSearchChanged,
      handleClickResetFilter,
      handleProductChanged,
      // datas,
      // statuses,
    };
  },
  data: () => ({
    submitting: false,
    url_: '',
    report: {},
    emet: {},
    purchase: [],
  }),
  mounted() {
    this.getReport();
    this.getPurchase();
    setInterval(this.getExpiryCounter, 1000);
  },
  methods: {
    getPurchase() {
      this.axios
        .get(
          `v1/econtract/inventory/client/purchase_v2?date=${this.dates}&status=${this.status}&search=${this.search}&limit=${this.limit}`
        )
        .then((response) => {
          let res = response.data.data.purchase;
          this.purchase = res;
        });
    },
    getExpiryCounter: function () {
      for (var idx in this.purchase) {
        var currentTime = Date.now();
        var timer =
          new Date(this.purchase[idx].expiry_date).getTime() - currentTime;

        var day = timer / 1000 / 60 / 60 / 24;
        // get hour
        var hour = (day % 1) * 24;
        // get min
        var min = (hour % 1) * 60;
        // get sec
        //var sec = (min % 1) * 60;

        var appndDate =
          Math.floor(hour) + ' jam, ' + Math.floor(min) + ' menit ';

        if (timer > 0) {
          Vue.set(this.purchase[idx], 'exp', appndDate);
        } else {
          Vue.set(this.purchase[idx], 'exp', 'EXPIRED');
        }
      }
    },
    getReport() {
      this.axios
        .get(`v1/econtract/web_app/client_balance_summary`)
        .then((res) => {
          this.report = res.data.data.esgn;
          this.emet = res.data.data.emet;
        });
    },
    getStatus() {
      // console.log(this.status)
      this.axios
        .get(
          `v1/econtract/inventory/client/purchase_v2?date=${this.dates}&status=${this.status}&search=${this.search}&limit=1000`
        )
        .then((response) => {
          let res = response.data.data.purchase;
          this.purchase = res;
        });
    },
    getJson(str) {
      if (str === '') {
        const userStr = str;
        return userStr;
      } else {
        const userStr = JSON.parse(str);
        return userStr;
      }
      // console.log(str);
      // let result = Object.fromEntries(str.map(e => e.split(":")))
    },
    getExport() {
      this.submitting = true;
      const params = {};
      const route = router.currentRoute;
      if (route.query.status) params.status = route.query.status;
      // if (route.query.product) params.product = route.query.product;
      if (route.query.date && Array.isArray(route.query.date))
        params.date = route.query.date.join(',');
      this.axios
        .get(`v1/econtract/inventory/client/purchase/export`, {
          params,
        })
        .then((res) => {
          this.submitting = false;
          const data = res?.data;
          const isError = data?.status === 'error';
          if(isError) {
            this.snackbar.text = data?.message;
            this.snackbar.state = true;
            this.snackbar.status = "red";
            return;
          }
          this.url_ = data?.path;
          this.forceFileDownload(this.url_, `Invoice-${Date.now()}`);
          this.snackbar.text = 'Successfully export data';
          this.snackbar.state = true;
        })
        .catch((res) => {
          this.submitting = false;
          this.snackbar.text = res.response.data.message;
          this.snackbar.state = true;
        });
      // }
    },
    forceFileDownload(url, fileName) {
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `${fileName}.pdf`); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>

<style lang="scss">
.card-header {
  &__wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  &__item {
    padding: 31px 20px;
    @media (min-width: 768px) {
      padding: 31px 40px;
    }
    box-shadow: inset 0px -1px 0px #e4e4e7;
    background: rgb(255, 255, 255);
    &-group {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column: span 3 / span 3;
      padding: 31px 61px;
      background: rgb(255, 255, 255);
      box-shadow: inset 0px -1px 0px #e4e4e7;
    }
  }
  &__card {
    &-title {
      color: #9e9e9e;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 18px;
      span {
        font-size: 13px;
      }
      &-h2 {
        font-size: 1.5rem;
        span {
          font-size: 13px;
        }
      }
    }
  }
}
.content {
  margin-top: 35px;
  padding-top: 20px;
  background: #ffffff;
  &-header {
    display: flex;
    justify-content: space-between;
    &__right {
      display: flex;
      align-items: center;
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
</style>
