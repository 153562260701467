import axios from "axios";
import url from "./api";
import { registerStatus } from "@/model/new-auth";

// const access_key = process.env.VUE_APP_ACCESS_KEY;
const offline = false;

const state = () => {
  return {
    user: null,
    has_purchased: null,
    register_status: { isCompleted: null, step: null },
  };
};

const mutations = {
  SET_USER(state, user) {
    axios.defaults.headers.common["x-access-token"] = user.token;
    localStorage.setItem("pensign_auth", JSON.stringify(user));
    state.user = user;
  },
  RESET_USER(state) {
    state.user = null;
  },
  SET_PURCHASED(state, value) {
    state.has_purchased = value;
  },
  SET_REGISTER_STATUS(state, value) {
    state.register_status = value;
  },
};

const getters = {
  user: (state) => state.user,
  isLoggedIn: (state) => !!state.user && !!state.user.token,
};

const actions = {
  async login({ commit }, user) {
    if (offline) {
      if (user.email == "admin@gm.com" && user.password == "admin") {
        const token = "Basic " + "token";
        const user = user;
        sessionStorage.setItem("token", token);
        axios.defaults.headers.common["x-access-token"] = token;
        commit("SET_USER", { token: token, user: user });
      }
    } else {
      return new Promise((resolve, reject) => {
        axios
          .post(url.admin_login, user, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => res.data)
          .then((res) => {
            // if (res.code === 200) {
              let user_login = res.data;
              commit("SET_USER", user_login);
              resolve(res);
            // } else {
            //   resolve(res);
            // }
          })
          .catch((error_code) => {
            reject(error_code);
          });
      });
    }
  },
  async logout({ commit }) {
    return new Promise((resolve) => {
      commit("RESET_USER");
      localStorage.removeItem("pensign_auth");
      delete axios.defaults.headers.common["x-access-token"];
      resolve("logged out");
    });
  },

  // async update_password({ commit }, data) {
  //   return new Promise((resolve, reject) => {
  //     axios.put(url.admin_update_password, data)
  //       .then(res => { resolve(res.data) })
  //       .catch(error => { reject(error) })
  //   })
  // },

  async get_user({ commit, dispatch }) {
    const user = JSON.parse(localStorage.getItem("pensign_auth"));
    if (user) {
      await axios
        .get(url.admin_detail, { headers: { "x-access-token": user.token } })
        .then(({ data }) => {
          if (data?.code && data?.code != 200) throw new Error("Unauthorized.");
          user.account = data?.data;
          commit("SET_USER", user);
        })
        .catch((err) => {
          console.log('[err] get user store', err);
          dispatch("logout");
        });
    }
  },
  async checkPackage({ commit }) {
    return axios
      .get("v1/subscription/package-active")
      .then((res) => res.data)
      .then((res) => {
        const data = res.data;
        if (data.isActive && !data.isExpired) commit("SET_PURCHASED", false);
        else commit("SET_PURCHASED", true);
      });
  },

  async registerStatus({ commit }) {
    return registerStatus()
      .then((res) => res.data)
      .then((res) => {
        commit("SET_REGISTER_STATUS", res.data);
      });
  },

  async verifyTwoFactor({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(url.continue_authenticator, data)
        .then((res) => res.data)
        .then((res) => {
          let user_login = res.data;
          commit("SET_USER", user_login);
          resolve(res);
        })
        .catch((error_code) => {
          console.log('[error_code] verifyTwoFactor store', error_code);
          reject(error_code);
        });
    });
  }
};

export default { state, mutations, getters, actions };
