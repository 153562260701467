<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Approved Detail</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="table mb-3 col-md-8">
          <div class="row">
            <div class="col-sm-12">
              <v-btn color="primary" dark class="ma-1 px-3" :to="`/disbursement-rilis/${serverParams.id}`">
                Process Transaction
              </v-btn>
            </div>
          </div>

          <v-card class="mt-2" elevation="1">            
            <v-card-actions class="grey lighten-3">
              <v-list-item class="grow pr-0 pl-0">                
                <v-list-item-content>
                  <v-list-item-title>AMOUNT</v-list-item-title>
                  <p class="text-black font-weight-medium fs-18">
                    Rp {{ formatPrice(dataDetail.amount) }}
                  </p>
                </v-list-item-content>

                <v-row
                  align="center"
                  justify="end"
                >
                  <v-chip
                    v-if="dataDetail.status === 'wait validation'"
                    color="#FF6F00"
                    outlined
                    class="text-capitalize"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'wait approval'"
                    color="#F0AB0B"
                    outlined
                    class="text-capitalize"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'failed'"
                    color="#E53935"
                    outlined
                    class="text-capitalize"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'processing'"
                    color="#3949AB"
                    outlined
                    class="text-capitalize"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'canceled'"
                    color="#D81B60"
                    outlined
                    class="text-capitalize"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else-if="dataDetail.status === 'completed'"
                    color="#0277BD"
                    outlined
                    class="text-capitalize"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                  <v-chip
                    v-else
                    :color="dataDetail.status == 'approved' ? 'success' : '#ADADAD'"
                    outlined
                    class="text-capitalize"
                  >
                    {{ dataDetail.status }}
                  </v-chip>
                </v-row>
              </v-list-item>
            </v-card-actions>
            <v-simple-table height="300px">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td style="width:30%;">DATE</td>
                    <td>{{ dataDetail.approved_at || "-" }}</td>
                  </tr>
                  <tr>
                    <td>BANK ACCOUNT NO.</td>
                    <td>{{ dataDetail.receipient_account_number }}</td>
                  </tr>
                  <tr>
                    <td>BANK CODE</td>
                    <td class="text-uppercase">{{ dataDetail.receipient_bank_code }}</td>
                  </tr>
                  <tr>
                    <td>ACCOUNT NAME</td>
                    <td class="text-capitalize">{{ dataDetail.receipient_account_name }}</td>
                  </tr>
                  <tr>
                    <td>REMARKS</td>
                    <td class="text-capitalize">{{ dataDetail.remark }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <v-snackbar
            v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>        
      </div>
    </v-card-text>   

  </v-card>
</template>

<script>
import axios from "axios";
import { formatPrice, formatDate } from "@/lib/formatDate";

export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Single Disbursement',
        disabled: false,
        href: '/disbursement?tab=approved',
      },
      {
        text: 'Approved Detail',
        disabled: true,
        href: '#',
      },
    ],
    dataDetail: {},
    showMessage: '',
    isError: false,
    isLoading: false,
    serverParams: {
      id: "",
      type: ""
    },
  }),
  mounted() {
    this.getListDisbursmentDetail()
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    idParam: {
      get() {
        return this.$route.params.id;
      }
    }
  },
  methods: {
    getListDisbursmentDetail() {
      return new Promise((resolve, reject) => {
          axios
          .get(`v1/etransfer/disbursement/detail/${this.idParam}` )
          .then(response => {
            this.dataDetail = response.data.result.data;
            this.serverParams.id = this.dataDetail.id;
            if(this.dataDetail.type == "bulk") {
              this.serverParams.type = this.dataDetail.type;
            } else {
              this.serverParams.type = "";
            }
            this.serverParams.type = this.dataDetail.type;
            this.isLoading = false;
            // console.log('object', this.dataDetail);
            resolve(response);
          })
          .catch(error => {
              reject(error);
          });
      });
    },

    formatDate(value) {
      return formatDate(value);
    },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
</style>