require('./lib');

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './lib/vuetify';
import VueCompositionAPI from '@vue/composition-api';
// import { mapActions } from 'vuex';

import { VueReCaptcha } from 'vue-recaptcha-v3'

// For more options see below
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY }) // VUE_APP_RECAPTCHA_SITE_KEY


import VueTour from 'vue-tour';
Vue.use(VueCompositionAPI);
const moment = require('moment');

require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);
Vue.use(require('vue-moment'), {
  moment,
});

if(localStorage.getItem('pensign_auth')) {
  store.dispatch('getAuthenticator');
  // mapActions('authenticator', ['getAuthenticator']);
}


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
