<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="px-0 py-1 mx-5 ml-0 mr-0 text-black fs-20 font-weight-bold">
      {{ name }}
    </v-card-title>
    <div class="py-4">
      <v-card>
        <div class="pa-5">
          <v-card-title class="px-0 pt-0 text-black pb-7 fs-20 font-weight-bold">
            {{ title }}
          </v-card-title>
          <p class="mb-0">These keys will allow you to authenticate API requests.</p>
        </div>
        <div>
          <v-simple-table>
            <template v-slot:default>
              <thead class="bg-grey">
                <tr>
                  <th class="px-5 text-left">
                    NAME
                  </th>
                  <th
                    style="max-width: 460px; margin-left: 0; margin-left: 0; padding: 0;"
                    class="text-left"
                  >
                    TOKEN
                  </th>
                  <th class="px-5 text-left">
                    CREATED
                  </th>
                  <th class="sr-only"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ publicKey && publicKey.name }}
                  </td>
                  <td
                    @click="handleCopy(publicKey && publicKey.token)"
                    style="width: 30%; padding: 20px 0; max-width: 460px;"
                  >
                    <v-tooltip right>
                      <template v-slot:activator="{on: tooltip}">
                        <code
                          title="Click to Copy"
                          style="background: none; user-select: none; cursor: pointer; display: block;"
                          v-on="{...tooltip}"
                        >
                          {{ publicKey && publicKey.token }}
                        </code>
                      </template>
                      <span>
                        <svg
                          width="18"
                          height="18"
                          fill="none"
                          viewBox="0 0 18 18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 6.75H8.25C7.42157 6.75 6.75 7.42157 6.75 8.25V15C6.75 15.8284 7.42157 16.5 8.25 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V8.25C16.5 7.42157 15.8284 6.75 15 6.75Z"
                            stroke="white"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M3.75 11.25H3C2.60218 11.25 2.22064 11.092 1.93934 10.8107C1.65804 10.5294 1.5 10.1478 1.5 9.75V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H9.75C10.1478 1.5 10.5294 1.65804 10.8107 1.93934C11.092 2.22064 11.25 2.60218 11.25 3V3.75"
                            stroke="white"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Click to Copy
                      </span>
                    </v-tooltip>
                  </td>
                  <td class="px-5">
                    {{ publicKey && publicKey.created_at }}
                  </td>
                  <td>
                    <v-menu
                      top
                      :close-on-click="true"
                      offset-y
                      offset-x
                      bottom
                      left
                    >
                      <template v-slot:activator="{on, attrs}">
                        <button v-on="on" v-bind="attrs">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style="width: 20px"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                            />
                          </svg>
                        </button>
                      </template>

                      <v-list>
                        <v-list-item style="min-height: 22px;">
                          <v-list-item-title class="text-sm-body-1">
                            Revoke Key
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ secretKey && secretKey.name }}
                  </td>
                  <td
                    class="d-flex flex-column"
                    style="height: auto; padding: 20px 0; max-width: 460px;"
                  >
                    <div @click="handleCopy(secretKey && secretKey.token)" v-if="showKey">
                      <v-tooltip right>
                        <template v-slot:activator="{on: tooltip}">
                          <code
                            style="user-select: none; background: none; cursor: pointer; display: block;"
                            v-on="{...tooltip}"
                            title="Click to Copy"
                          >
                            {{ secretKey && secretKey.token }}
                          </code>
                        </template>
                        <span>
                          <svg
                            width="18"
                            height="18"
                            fill="none"
                            viewBox="0 0 18 18"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15 6.75H8.25C7.42157 6.75 6.75 7.42157 6.75 8.25V15C6.75 15.8284 7.42157 16.5 8.25 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V8.25C16.5 7.42157 15.8284 6.75 15 6.75Z"
                              stroke="white"
                              stroke-width="1.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.75 11.25H3C2.60218 11.25 2.22064 11.092 1.93934 10.8107C1.65804 10.5294 1.5 10.1478 1.5 9.75V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H9.75C10.1478 1.5 10.5294 1.65804 10.8107 1.93934C11.092 2.22064 11.25 2.60218 11.25 3V3.75"
                              stroke="white"
                              stroke-width="1.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Click to Copy
                        </span>
                      </v-tooltip>
                    </div>
                    <v-btn
                      v-else
                      @click="showKey = !showKey"
                      outlined
                      color="primary"
                    >
                      Reveal Key
                    </v-btn>
                    <v-btn
                      class="mt-4"
                      v-if="showKey"
                      @click="showKey = !showKey"
                      outlined
                      color="primary"
                    >
                      Hide Key
                    </v-btn>
                  </td>
                  <td class="px-5">
                    {{ secretKey && secretKey.created_at }}
                  </td>
                  <td>
                    <v-menu
                      top
                      :close-on-click="true"
                      offset-y
                      offset-x
                      bottom
                      left
                    >
                      <template v-slot:activator="{on, attrs}">
                        <button v-on="on" v-bind="attrs">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            style="width: 20px"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                            />
                          </svg>
                        </button>
                      </template>

                      <v-list>
                        <v-list-item style="min-height: 22px;">
                          <v-list-item-title class="text-sm-body-1">
                            Revoke Key
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card>
    </div>
    <v-snackbar v-model="Alert">
      {{ message }}
      <template v-slot:action="{attrs}">
        <v-btn color="blue" text v-bind="attrs" @click="Alert = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import {reactive, toRefs, onMounted} from "@vue/composition-api";
import axios from 'axios'

export default {
  setup() {
    const state = reactive({
      name: "API Setting",
      title: "API Key",
      subtitle: "These keys will allow you to authenticate API requests.",
      publicKey: null,
      secretKey: null,
      showKey: false,
      message: "",
      Alert: false,
    });

    const handleCopy = (value) => {
      try {
        navigator.clipboard.writeText(value);
        state.Alert = true;
        state.message = "Copied to clipboard";
      } catch (error) {
        state.Alert = true;
        state.message = error.message;
      }
    };


    onMounted(async ()=>{
      let { data:data } = await axios.get('v1/auth/dashboard/api_key')
      state.publicKey = data.data.publicKey
      state.secretKey = data.data.secretKey
    })

    return {
      handleCopy,
      ...toRefs(state),
    };
  },
};
</script>
