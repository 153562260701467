<template>
  <v-row style="min-height: 100vh">
    <v-col cols="12">
      <v-row justify="center" align="center" style="height: 100%">
        <v-col class="px-4 py-16 d-flex flex-column" cols="12">
          <v-row justify="center">
            <v-col cols="12" xl="4" lg="4" md="8" sm="10">
              <v-card elevation="2" class="my-8 rounded-lg" outlined>
                <v-card-title class="mx-4">
                  <div v-if="verification_method === 'AUTHENTICATOR'">
                    <div class="text-h5">Two-Factor Authentication</div>
                    <div class="caption">Silahkan masukkan kode two-factor authentication akun Anda</div>
                  </div>
                  <div v-else>
                    <div class="text-h5">Recovery Code</div>
                    <div class="caption">Silahkan masukkan salah satu kode recovery code akun Anda</div>
                  </div>
                </v-card-title>
                <v-card-text class="pb-0 my-0">
                  <v-form
                    ref="loginForm"
                    :disabled="loading"
                    lazy-validation
                  >
                    <div class="mx-4 mb-4">
                      <v-otp-input 
                        v-model="verification_value"
                        :length="verification_method === 'AUTHENTICATOR' ? '6' : '8'">
                      </v-otp-input>
                    </div>
                  </v-form>

                  <v-snackbar v-model="showAlert" top text color="error">
                  {{ alertMessage }}
                </v-snackbar>
                </v-card-text>
                <v-card-actions class="pt-0 mx-0 d-flex flex-column">
                  <v-btn
                    color="primary"
                    large
                    block
                    @click="onSubmit"
                    :loading="loading"
                    :disabled="loading">
                    Verifikasi
                  </v-btn>
                  <!-- <div v-if="verification_method === 'AUTHENTICATOR'" class="mt-4 font-weight-medium">
                    Ada masalah saat mendapatkan kode? <a @click="changeInputType()">recovery code</a>
                  </div>
                  <div v-else class="mt-4 font-weight-medium">
                    Ingin menggunakan authentication app?  <a @click="changeInputType()">coba sekarang</a>
                  </div> -->
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      showAlert: false,
      alertMessage: '',
      verification_value: '',
      verification_method: 'AUTHENTICATOR',
    };
  },
  computed: {
    codeRequired() {
      return [v => !!v || 'Kode verifikasi harus diisi'];
    },
    userLogin() {
      return JSON.parse(localStorage.getItem('pensign_login'));
    },
  },
  methods: {
    changeInputType() {
      this.verification_method = this.verification_method === 'AUTHENTICATOR' ? 'RECOVERY_CODE' : 'AUTHENTICATOR';
    },
    async onSubmit() {

      if(this.verification_value === '') {
        this.showAlert = true;
        this.alertMessage = "Kode verifikasi harus diisi";
        return;
      }

      this.loading = true;
      try {

        let data = {
          email: this.userLogin.email,
          password: this.userLogin.password,
          verification_value: this.verification_value,
          verification_method: this.verification_method,
        }

        let res = await this.$store.dispatch('verifyTwoFactor', data)
        await this.$store.dispatch("get_user");
        
        if(res.data.token) {
          this.redirectAfterLogin(res.data.account.is_reset);

          localStorage.removeItem('pensign_login');
        }
        
        
      } catch (error) {
        console.log('[err] handle login', error.message);
        // let msg = error.message;
        // if (error.response?.data?.msg) msg = error.response?.data?.msg;
        this.showAlert = true;
        this.alertMessage = "Kode verifikasi tidak valid";
        
      } finally {
        this.loading = false;
      }
    },
    redirectAfterLogin(reset) {
      if (reset) {
        return this.$router.push("/update-password");
      } else if (localStorage.getItem("pensign_redirect") != null) {
        this.$router.push(localStorage.getItem("pensign_redirect"));
        localStorage.removeItem("pensign_redirect");
      } else {
        location.href = "/dashboard";
      }
    },
  },
};
</script>