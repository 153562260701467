<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="px-0 pt-0 pb-0 text-black fs-20 font-weight-bold">Pilih Metode Pembayaran</v-card-title>

    <v-row>
      <v-col cols="8">
        <v-card-text class="px-0 pt-0">
          <v-form
            ref="forms"
            @submit.prevent="submit"
            v-model="valid"
          >
            <v-card elevation="1" class="mt-4">
              <v-card-title class="text-black fs-20 font-weight-bold">Select a funding source</v-card-title>

              <v-divider></v-divider>

              <!-- <div class="px-5 py-5 bg-grey">
                <v-row class="bg-grey">
                  <v-col cols="6">
                    <p class="mb-0 text-black font-weight-thin">Your Balance</p>
                  </v-col>
                  <v-col cols="6" class="d-flex align-center justify-content-end">
                    <div class="d-flex" style="flex-direction:column;">
                      <h2 class="mb-0 text-red">Rp {{ formatPrice(dataDetail.total_price) }}</h2>
                      <p class="mt-2 mb-0" style="font-size:12px !important;" v-if="showBalance">
                        <v-icon class="mr-1" color="#FF9800">ri-error-warning-fill</v-icon>
                        Balance tidak cukup untuk membayar tagihan
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-divider></v-divider> -->

              <!-- <v-row class="px-5 py-6">
                <v-col cols="12">
                  <v-radio-group
                    v-model="serverParams.payment_channel"
                    row
                    :rules="[v => !!v || 'Type disbursement is required']"
                    class="mt-0"
                    @change="checkBankTransfer(serverParams.payment_channel)"
                  >
                    <div v-for="(list, index) in dataDetail.fund" :key="index">
                      <v-radio
                        :disabled="disabled"
                        class="type_fund"
                        :label="list.label"
                        :value="list.name"
                      ></v-radio>
                    </div>
                  </v-radio-group>
                </v-col>
              </v-row>

              <v-divider></v-divider> -->

              <v-row class="px-5 py-6">
                <v-col cols="12">
                  <p class="px-0 mb-0 text-black font-weight-thin fs-14">Select Bank </p>
                  <div class="d-flex">
                    <v-radio-group
                      v-model="serverParams.bank_id"
                      row
                      v-for="(list, idx) in listBank" :key="idx"
                      @change="checkSeledtedBank(serverParams.bank_id)"
                    >
                      <v-radio
                        class="deposit"
                        :value="list.id"
                      >
                        <template v-slot:label>
                          <div><img :src="list.image"></div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>

              <v-row class="px-5 pt-0 pb-6">
                <v-col cols="12">
                  <p class="mb-0 text-black font-weight-thin">
                    You have the option to process the transaction with your balance or with a direct bank transfer. If your balance is insufficient, your transaction will be completed by bank transfer.
                  </p>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <template>
                <v-card-actions>
                  <div class="row">
                    <div class="px-0 text-right col-sm-12">
                      <v-btn color="primary" outlined class="px-5 mr-2" @click="validateField">
                        Batal
                      </v-btn>
                      <v-btn color="primary" dark class="px-3" v-on:click="requestOtp()" :loading="isLoading" v-if="buttonContinueBalance">
                        Bayar Sekarang
                      </v-btn>
                      <v-btn color="primary" dark class="px-3" v-on:click="nextStep()" :loading="isLoading">
                        Bayar Sekarang
                      </v-btn>
                    </div>
                  </div>
                </v-card-actions>
              </template>

            </v-card>
          </v-form>

          <v-snackbar
              v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
              Close
              </v-btn>
            </template>
          </v-snackbar>
        </v-card-text>
      </v-col>
    </v-row>

    <v-dialog
      v-model="pin"
      width="500"
      persistent
    >
      <v-card class="">
        <div class="text-end">

        <v-btn
          icon
          dark
          @click="pin = false"
          color="#333"
          class="float-right"
          >
            <v-icon>ri-close-fill</v-icon>
          </v-btn>
        </div>

        <v-card-text class="py-5 my-3 text-center">
          <img src="@/assets/images/img-otp.png" class="mb-3">
          <p class="text-black fs-16 font-weight-medium">Please input your PIN</p>
          <v-row>
            <v-col id="kode" cols="12" sm="8" class="mx-auto">
              <form @submit.prevent="handleSubmit()" ref="pinForm">
                <PincodeInput autocomplete="false" autofocus v-model="code" secure change="" :value="code" :length="6" />
                <!-- <v-btn
                  color="primary"
                  dark
                  type="submit"
                  class="mt-4"
                  :loading="isLoading"
                >
                  Continue
                </v-btn> -->
              </form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogLoading" width="700" persistent>
      <v-card>
        <v-icon
          class="px-4 py-4"
          small
          color="#CFD8DC"
          @click="dialogLoading = false"
          >ri-close-line</v-icon
        >
        <v-row class="px-5 py-5 mx-0 my-0">
          <v-col cols="12">
            <div
              class="d-flex align-center justify-content-center"
              style="gap: 20px;"
            >
              <img src="@/assets/images/biru.png" />
              <img src="@/assets/images/loading.png" />
              <img src="@/assets/images/orange.png" />
            </div>
            <div class="mt-5 text-center">
              <p class="mb-2 text-black font-weight-bold">
                Kami sedang mengecek Transferanmu
              </p>
              <p class="mb-0 text-black font-weight-thin">
                It may take a while to complete the transaction
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

  </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";
import axios from "axios";
import PincodeInput from "vue-pincode-input";

export default {
  components: { PincodeInput },
  data: () => ({
    dialogLoading: false,
    code: "",
    valid: true,
    isLoading: false,
    disabled: false,
    buttonContinueBalance: false,
    buttonContinueBank: false,
    disabledContinueBank: false,
    showBankList: false,
    pin:false,
    isError: false,
    submiting: false,
    showMessage: "",
    listBank: [],
    serverParams: {
      // bank_id: "",
      disbursement_id: "",
      otp_code: "",
      payment_channel: "bank_transfer"
    },
    dataDetail: {},
    paramOtp: {
      disbursement_id: ""
    },
    showBalance: false,
  }),
  mounted() {
    this.getPaymentChannel();
  },
  watch: {
    code() {
      if(this.code.length === 6 ) this.handleSubmit()
    }
  },
  computed: {
    idParam: {
      get() {
        return this.$route.params.id;
      }
    },
  },
  methods: {
    getPaymentChannel() {
      return new Promise((resolve, reject) => {
          axios
          .get(`v1/econtract/inventory/client/payment_channel?order_id=${this.idParam}` )
          .then(response => {
            const { data } = response.data;
            this.dataDetail = data;
            this.listBank = this.dataDetail.fund[0].data;
            // this.isLoading = false;
            resolve(response);
          })
          .catch(error => {
              reject(error);
              this.isLoading = false;
          });
      });
    },

    requestOtp() {
      this.pin = true;
      // setTimeout(() => {
      //   this.isLoading = true;
      //   this.$store.dispatch("requestOtp", this.paramOtp)
      //   .then((response) => {
      //     const { data } = response;
      //     this.code = data.data.code;
      //     this.isError = true;
      //     this.showMessage = response.data.message;
      //     this.isLoading = false;
      //     this.serverParams.disbursement_id = this.dataDetail.id;
      //     this.serverParams.otp_code = this.code;
      //   })
      //   .catch((error) => {
      //     this.isLoading = false;
      //     this.isError = true;
      //     this.showMessage = error.response.data.message;
      //   });
      // }, 500);
    },

    async handleSubmit() {
      this.dialogLoading = true;
      this.isError = true;
      this.showMessage = 'sukses';
      setTimeout(()=> {
        this.$router.push({name: 'inventory'})
        this.code = ""
      }, 1500)
      // setTimeout(() => {
      //   this.isLoading = true;
      //   this.$store.dispatch("releaseDisbursement", this.serverParams)
      //   .then((response) => {
      //     this.isError = true;
      //     this.showMessage = response.data.message;
      //     this.isLoading = false;
      //     setTimeout( () => {
      //       this.$router.push({path: '/history-money'})
      //     }, 1000);
      //   })
      //   .catch((error) => {
      //     this.isLoading = false;
      //     this.isError = true;
      //     this.showMessage = error.response.data.message;
      //   });
      // }, 50);
    },

    checkBankTransfer(type) {
      if(type === 'bank_transfer') {
        this.buttonContinueBank = true;
        // this.buttonContinueBalance = false;
        this.disabledContinueBank = true;
        this.disabled = true;
        // this.dataDetail.balance.enable = true;
        // this.showBankList = true;
      } else if(type === 'balance') {
        this.disabled = false;
        if(this.dataDetail.balance.saldo < this.dataDetail.total_price) {
          this.showBalance = true;
          this.buttonContinueBalance = false;
          this.buttonContinueBank = false;
        } else {
          this.buttonContinueBalance = true;
          this.buttonContinueBank = false;
        }
      }
    },

    checkSeledtedBank(id) {
      if(id !== '') this.disabledContinueBank = false;
    },

    nextStep() {
      const data ={
        order_id: this.$route.params.id,
        payment_method:
        {
          name:this.serverParams.payment_channel,
          detail: {
              id: this.serverParams.bank_id
          }
        }
      }
      this.axios.post(`v1/econtract/inventory/client/payment_method`, data)
      .then((response) => {
        // console.log(response.data.msg)
        // this.code = response.data.data.code;
        this.isError = true;
        this.showMessage = response.data.msg;
        this.submiting = true;
        setTimeout( () => {
            this.$router.push({ name: 'peyment-method-detail', params: { id: this.$route.params.id} })
        }, 1500);        
      })
      .catch((error) => {
        console.log(error)
        this.submiting = false;
        this.isError = true;
        this.showMessage = error.response.data.msg;
      });

      // this.$router.push({name: 'peyment-method-detail', params: { id: 1 }})
      // this.$store.dispatch("requestDeposit", this.serverParams)
      // .then((response) => {
      //   this.code = response.data.data.code;
      //   this.isError = true;
      //   this.submiting = true;
      //   this.showMessage = response.data.message;
      //   setTimeout( () => {
      //     this.$router.push({name: 'balance-deposit-detail', params: { id: this.code }})
      //   }, 800);
      // })
      // .catch((error) => {
      //   this.submiting = false;
      //   this.isError = true;
      //   this.showMessage = error.response.data.message;
      // });
    },

    formatPrice(value) {
      return formatPrice(value);
    },

    validateField() {
      // this.$refs.forms.validate();
          this.$router.push({name: 'inventory'})

    }
  },
};
</script>

<style scoped lang="scss">
  .justify-content-end {
    justify-content: end;
  }
  .justify-content-center {
    justify-content: center;
  }
  .bg-grey {
    background-color: #F9F9F9;
  }
  .v-radio.type_fund.v-radio--is-disabled  {
    border-color: #E0E0E0;
    background-color: #BDBDBD;
    color: #EEEEEE !important;
  }
  .v-radio.type_fund.v-item--active {
    border-color: #409eff;
    background-color: #409eff;
    color: #fff !important;
  }
  .v-radio.type_fund:hover {
    border-color: #409eff;
    background-color: #409eff;
    color: #fff !important;
  }

  .v-radio.type_fund {
    padding: 10px 20px 10px 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
  }
</style>

<style>
  .v-radio.type_fund .v-input--selection-controls__input {
    display: none !important;
  }
  .type_fund .theme--light.v-label {
    color: inherit;
  }

  .v-radio.deposit.theme--light.v-item--active {
    border: 2px solid #409eff;
  }
  .v-radio.deposit:hover {
    border-color: #409eff;
  }
  .v-radio.deposit .v-input--selection-controls__input {
    display: none;
  }
  .v-radio.deposit {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
  }
  .v-radio.deposit img {
    width: 100px;
  }
  .deposit .theme--light.v-label {
    color: inherit;
  }
</style>