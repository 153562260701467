<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="px-0 pt-0 text-black fs-20 font-weight-bold">
      Riwayat Pemakaian
    </v-card-title>
    <section class="content">
      <v-card class="px-5">
        <div class="content-header row">
          <v-col cols="12">
            <v-btn
              :color="$route.query.type == 'token' ? 'primary' : ''"
              class="mr-2"
              value="all"
              @click="handleTypeChanged('token')"
            >
              Token
            </v-btn>
            <!-- <v-btn
              :color="$route.query.type == 'kyc' ? 'primary' : ''"
              class="mr-2"
              value="privy"
              @click="handleTypeChanged('kyc')"
            >
              Kuota KYC
            </v-btn> -->
          </v-col>
          <div class="col-md-6 row">
            <div class="col-lg-6 col-md-12">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                offset-y
                max-width="320"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    solo
                    flat
                    clearable
                    readonly
                    placeholder="Pilih Rentang Tanggal"
                    v-bind="attrs"
                    v-on="on"
                    v-model="dateRangeText"
                  >
                    <template #append>
                      <v-icon v-bind="attrs" v-on="on">ri-calendar-line</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dates"
                  @change="handleFilterDate"
                  range
                  width="100%"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="col-lg-6 col-md-12">
              <v-text-field
                outlined
                append-icon="$search"
                class=""
                placeholder="Cari berdasarkan ID Dokumen/Penandatangan"
                solo
                v-model="params.search"
                flat
                @input="handleFilterQuery"
              ></v-text-field>
            </div>
          </div>
          <div class="text-right col-md-6 content-header__right">
            <div class="row">
              <div class="col-md-12">
                <v-btn
                  class="mr-2"
                  outlined
                  color="primary"
                  @click="handleResetFilter"
                  >Reset</v-btn
                >
                <v-btn
                  color="grey darken-4"
                  class="white--text"
                  @click="handleExportData()"
                  :loading="isExportLoading"
                  style="min-height: 39px"
                >
                  <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Export
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <v-data-table
          class="mt-5"
          :headers="headers"
          :items="items || []"
          :loading="isLoading"
          :page="params.page"
          :server-items-length="pageData.total_data"
          :items-per-page="params.limit"
          @update:page="handlePageChanged($event)"
          @update:items-per-page="handleLimitChanged($event)"
        >
          <template v-slot:[`item.qty`]="{ item }">
            {{ item.token[1].value }}
          </template>
          <template v-slot:[`item.types`]> ESGN </template>

          <!-- <template v-slot:[`item.items`]="{item}">
            <div
              v-for="(product, id) in item.items"
              :key="id"
              class="py-3 d-flex"
            >
              <div class="justify-center mr-3 d-flex align-center">
                <img
                  style="width: 70%;"
                  src="@/assets/images/materai-icon.png"
                  alt=""
                />
              </div>
              <div>
                <h4>{{ product.title }}</h4>
                <p>{{ product.description }}</p>
              </div>
            </div>
          </template>
          <template v-slot:[`item.amount`]="{item}">
            <div
              v-for="(product, id) in item.items"
              :key="id"
              class="py-3 d-flex"
            >
              <div>
                <p>Rp. {{  formatToPrice(product.price) }}</p>
              </div>
            </div>
          </template> -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatToDate(item.createdAt) }}
          </template>
        </v-data-table>
      </v-card>
      <v-snackbar top v-model="snackbar.state" color="primary" outlined>
        <div v-html="snackbar.text"></div>
        <template v-slot:action="{ attrs }">
          <v-btn
            small
            icon
            color="error"
            v-bind="attrs"
            @click="snackbar.state = false"
          >
            <v-icon>$close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </section>
  </v-card>
</template>

<script>
import { onMounted, computed, ref } from '@vue/composition-api';
import axios from 'axios';
import { formatPrice } from '../../../lib/formatDate';

// const states = {
//   dates: [],
//   statuses: [],
//   status: [],
//   search: '',
//   menu: '',
//   balance: 0,
//   totalPoints: [],
//   datas: [],
//   limit: 10,
//   page: 1,
//   isLoading: false,
// };

// const statuses = ["Pending", "Completed"];

export default {
  setup(props, ctx) {
    const dateRangeText = computed(() => {
      if (dates.value) return dates.value.join(' ~ ');
      return '';
    });

    const route = ctx.root.$route;
    const router = ctx.root.$router;
    const snackbar = ref({});
    const params = ref({});
    const isLoading = ref(false);
    const isExportLoading = ref(false);
    const pageData = ref({});
    const tableData = ref();
    const dates = ref();
    const menu = ref();

    const headers = computed(() => {
      return route.query.type == 'token'
        ? [
            {
              text: 'TANGGAL',
              align: 'start',
              sortable: false,
              value: 'createdAt',
            },
            { text: 'NO. INVOICE', value: 'id' },
            { text: 'PEMILIK DOKUMEN', value: 'uploader' },
            { text: 'PENANDATANGAN', value: 'customer' },
            { text: 'ID DOKUMEN', value: 'document_id' },
            { text: 'JENIS TOKEN', value: 'types' },
            { text: 'JUMLAH', value: 'qty' },
          ]
        : [
            { text: 'TANGGAL', value: 'createdAt' },
            { text: 'NO. INVOICE', value: 'invoice' },
            { text: 'NAMA PENGGUNA', value: 'name' },
            { text: 'EMAIL', value: 'email' },
            { text: 'NIK', value: 'nik' },
            { text: 'JENIS KYC', value: 'type' },
          ];
    });

    const items = computed(() =>
      tableData.value ? tableData.value.results : []
    );

    function formatToPrice(value) {
      return formatPrice(value);
    }

    function formatToDate(value) {
      return value;
    }

    async function getData() {
      isLoading.value = true;
      const url =
        route.query.type == 'kyc'
          ? 'v1/econtract/web_app/kyc-usage-history'
          : 'v1/econtract/web_app/token-usage-history';
      let { data: response } = await axios
        .get(url, {
          params: params.value,
        })
        .finally(() => {
          isLoading.value = false;
        });
      if (response) {
        tableData.value = response.data;
        pageData.value = tableData.value.pagination;
      }
    }

    function handleLimitChanged(value) {
      if (value == -1) params.value.limit = pageData.value.total_data;
      else params.value.limit = value;
      getData();
    }

    function handleFilterDate(__dates) {
      if (Array.isArray(__dates) && __dates.length == 2) {
        if (__dates[0] > __dates[1]) __dates = [__dates[1], __dates[0]];
        dates.value = __dates;
        params.value.date = __dates.join(',');
      } else {
        params.value.date = undefined;
        dates.value = undefined;
      }
      getData();
    }

    const searchTimeout = ref();
    function handleFilterQuery() {
      clearTimeout(searchTimeout.value);
      searchTimeout.value = undefined;
      searchTimeout.value = setTimeout(() => {
        getData();
      }, 3e2);
    }

    function handlePageChanged(value) {
      params.value.page = value;
      getData();
    }

    function handleResetFilter() {
      dates.value = undefined;
      params.value = {
        page: params.value.page,
        limit: params.value.limit,
      };
      getData();
    }

    function handleExportData() {
      isExportLoading.value = true;
      const url =
        route.query.type == 'token'
          ? 'v1/econtract/web_app/token-usage-history/export'
          : 'v1/econtract/web_app/kyc-usage-history/export';
      this.axios
        .get(url, {
          params: { date: params.value.date },
        })
        .then((res) => {
          this.snackbar.text = 'Successfully export data';
          this.snackbar.state = true;

          const anchor = document.createElement('a');
          anchor.href = res.data.path;
          anchor.target = '_blank';
          anchor.download = anchor.href.split('/').pop();
          anchor.click();
          document.removeChild(anchor);
        })
        .finally(() => {
          isExportLoading.value = false;
        });
    }

    function handleTypeChanged(type) {
      router.push({ query: { type } });
      // console.log(type)
    }

    onMounted(async () => {
      params.value.page = 1;
      params.value.limit = 10;
      if (!route.query.type) router.replace({ query: { type: 'token' } });
      getData();
    });

    return {
      // var
      handleTypeChanged,
      dates,
      isExportLoading,
      isLoading,
      items,
      menu,
      params,
      snackbar,
      tableData,
      pageData,
      // computed
      dateRangeText,
      headers,
      // method
      formatToPrice,
      formatToDate,
      getData,
      handleLimitChanged,
      handlePageChanged,
      handleFilterQuery,
      handleFilterDate,
      handleResetFilter,
      handleExportData,
    };
  },
};
</script>

<style lang="scss">
.card-header {
  &__wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  &__item {
    padding: 31px 20px;
    @media (min-width: 768px) {
      padding: 31px 40px;
    }
    box-shadow: inset 0px -1px 0px #e4e4e7;
    background: rgb(255, 255, 255);
    &-group {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-column: span 3 / span 3;
      padding: 31px 61px;
      background: rgb(255, 255, 255);
      box-shadow: inset 0px -1px 0px #e4e4e7;
    }
  }
  &__card {
    &-title {
      color: #9e9e9e;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 18px;
      span {
        font-size: 13px;
      }
      &-h2 {
        font-size: 1.5rem;
        span {
          font-size: 13px;
        }
      }
    }
  }
}
.content {
  margin-top: 35px;
  padding-top: 20px;
  background: #ffffff;
  &-header {
    display: flex;
    justify-content: space-between;
    &__right {
      display: flex;
      align-items: center;
    }
  }
}
.capitalize {
  text-transform: capitalize;
}
.card-header__item-group {
  box-shadow: unset !important;
}

@media (max-width: 768px) {
  .card-header__item-group {
    grid-template-columns: auto !important;
    padding: 31px 20px !important;
  }
  .v-application--is-ltr .v-data-footer__select {
    margin: 0px;
  }
  .v-application--is-ltr .v-data-footer__select .v-select {
    margin-left: 5px;
    margin-right: 0px;
  }
  .v-application--is-ltr .v-data-footer__pagination {
    margin-right: 5px;
    margin-left: 5px;
  }
  .v-application--is-ltr .v-data-footer__icons-after .v-btn:first-child {
    margin-left: 0px;
  }
}
</style>
