<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <v-navigation-drawer
        id="navigasi"
        fixed
        floating
        v-model="drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :temporary="!$vuetify.breakpoint.mdAndUp"
        class="elevation-1 bg-navy"
      >
        <img
          src="@/assets/images/logo.png"
          alt="Avatar"
          class="ml-5 ma-3"
          style="width: 150px"
        />
        <v-list flat class="d-none">
          <p
            class="pt-2 pl-6 mb-1 font-weight-medium fs-12 text-blue-menu"
            style="letter-spacing: 0.25em"
          >
            E-METERAI
          </p>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-icon>
                <img src="@/assets/images/withdraw 1.svg" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Receive Money </v-list-item-title>
              </v-list-item-content>
            </template>
            <template>
              <div v-for="item in receive" :key="item.title">
                <v-list-item
                  active-class="menu-active"
                  class="transparent fs-14"
                  :to="item.link"
                  @click="pageChanged(0, item)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-body-1">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
          </v-list-group>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-icon>
                <img src="@/assets/images/money-bag 1.svg" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Send Money </v-list-item-title>
              </v-list-item-content>
            </template>
            <template>
              <div v-for="item in trans" :key="item.title">
                <v-list-item
                  active-class="menu-active"
                  class="transparent fs-14"
                  :to="item.link"
                  @click="pageChanged(0, item)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-body-1">
                      {{ item.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
          </v-list-group>
          <template>
            <div v-for="item in history" :key="item.title">
              <v-list-item
                active-class="menu-active"
                class="transparent text-semi fs-14 d-none"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <!-- active-class="menu-active" -->
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
        </v-list>

        <!-- <v-divider style="border-color:#0C3664;"></v-divider> -->

        <v-list flat>
          <p
            class="pt-2 pl-6 mb-1 font-weight-medium fs-12 text-blue-menu"
            style="letter-spacing: 0.25em"
          >
            <!-- E-CONTRACT -->
          </p>
          <template>
            <v-list-item
              active-class="menu-active"
              exact-active-class="menu-active"
              class="transparent fs-14 d-none"
              :to="{ name: 'list-certificate' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/icon-certif.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Certificate </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-14 d-none"
              :to="{ name: 'list-template' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/icon-temp.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Template </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :disabled="!enabledAuthenticator"
              exact-active-class="menu-active"
              active-class="menu-active"
              class="mb-2 transparent fs-14"
              :to="{ name: 'dashboard' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/home.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Beranda </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              :disabled="!enabledAuthenticator"
              exact-active-class="menu-active"
              active-class="menu-active"
              class="mb-2 transparent fs-14"
              :to="{ name: 'inventory-products' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/wallet-minus.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title data-v-step="1">
                  Inventori
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group 
              :disabled="!enabledAuthenticator"
              :value="false">
              <template v-slot:activator>
                <v-list-item-icon>
                  <img src="@/assets/images/presention-chart.svg" />
                </v-list-item-icon>
                <v-list-item-content data-v-step="2" class="mb-2">
                  <v-list-item-title> Laporan </v-list-item-title>
                </v-list-item-content>
              </template>
              <template>
                <div v-for="item in report" :key="item.title">
                  <v-list-item
                    active-class="menu-active"
                    class="mt-2 mb-2 transparent fs-14"
                    :to="item.link"
                    @click="pageChanged(0, item)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-body-1">
                        {{ item.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
            </v-list-group>

            <v-list-item
              :disabled="!enabledAuthenticator"
              exact-active-class="menu-active"
              active-class="menu-active"
              class="mb-2 transparent fs-14"
              :to="{ name: 'user-management' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/profile-2user.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title data-v-step="0">
                  Pengguna
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="false"
              exact-active-class="menu-active"
              active-class="menu-active"
              class="mb-2 transparent fs-14"
              :to="{ name: 'api-setting' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/candle.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title> Pengaturan API </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-group class="mb-2">
              <template #activator>
                <v-list-item-icon>
                  <img src="@/assets/images/document-code-2.svg" />
                </v-list-item-icon>
                <v-list-item-content class="">
                  <v-list-item-title>Menu Developer</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item
                exact-active-class="menu-active"
                active-class="menu-active"
                class="mt-2 mb-2 transparent fs-14"
                :to="{ name: 'developer' }"
              >
                <v-list-item-icon> </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> Overview </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                exact-active-class="menu-active"
                active-class="menu-active"
                class="mb-2 transparent fs-14"
                :to="{ name: 'developer-api' }"
              >
                <v-list-item-icon> </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title> Kelola API </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group> -->

            <v-list-item
              v-if="statusPartner"
              exact-active-class="menu-active"
              active-class="menu-active"
              class="mb-2 transparent fs-14"
              :to="{ name: 'ppob' }"
            >
              <v-list-item-icon>
                <img src="@/assets/images/home-hashtag.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title data-v-step="0"> PPOB </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <br />
          <br />
          <template>
            <div class="pa-2">
              <!-- SUBSCRIPTION PACKAGE -->
              <subscription-package />
              <!-- SUBSCRIPTION PACKAGE -->
              <v-list-item
                exact-active-class="menu-active"
                active-class="menu-active"
                class="transparent fs-16 pointer"
              >
                <v-list-item-icon>
                  <v-icon>ri-logout-box-line</v-icon>
                </v-list-item-icon>

                <v-list-item-content link @click="logout">
                  <v-list-item-title> Logout </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </template>
        </v-list>
      </v-navigation-drawer>

      <!-- Topnav / App bar -->
      <v-main
        id="tool"
        class="background"
        :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0 }"
      >
        <v-app-bar
          id="tool"
          color="background bg-navy"
          :style="{ marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0 }"
          flat
          fixed
          class="bg-navy"
        >
          <v-app-bar-nav-icon
            v-if="!$vuetify.breakpoint.mdAndUp"
            class="ma-2"
            @click="drawer = true"
          ></v-app-bar-nav-icon>
          <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>
          <img
            v-if="!$vuetify.breakpoint.mdAndUp"
            src="@/assets/images/logo.png"
            alt="Avatar"
            class="ml-5 ma-3"
            style="width: 150px"
          />

          <v-spacer></v-spacer>

          <v-btn
            class="mr-4"
            v-if="$route.path == '/dashboard'"
            icon
            @click="show_welcome = true"
          >
            <v-icon>ri-book-read-fill</v-icon>
          </v-btn>

          <v-btn
            :disabled="!enabledAuthenticator"
            class="d-none d-sm-flex"
            outlined
            elevation="1"
            style="border-radius: 6px"
            :to="{ name: 'dashboard', hash: '#package' }"
          >
            <span style="color: #cd7b2e">
              <v-icon left>ri-vip-crown-line</v-icon>
              Tingkatkan
            </span>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="ml-4 rounded-pill d-flex align-center"
                v-ripple
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar class="mr-2 elevation-1 white" size="40px">
                  <img src="@/assets/images/no-pict.png" alt="Avatar" />
                </v-avatar>
                <span class="font-weight-medium d-none d-sm-inline-block">{{
                  profile_name
                }}</span>
                <v-icon class="mx-2" small>$dropdown</v-icon>
              </div>
            </template>

            <v-list dense>
              <v-list-item link @click="historyInvoice">
                <v-list-item-title>
                  <v-icon>ri-user-line</v-icon>
                  Pengaturan
                </v-list-item-title>
              </v-list-item>
              <v-list-item link @click="logout">
                <v-list-item-title>
                  <v-icon>ri-logout-box-line</v-icon>
                  Logout
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <!-- notifications -->
        <!-- <v-navigation-drawer
          v-model="notif"
          absolute
          right
          style="top:62px;width:410px;"
          permanent
        >
          <v-list
            nav
            dense
            class="mx-3"
          >
            <v-list-item-action >
              <v-icon class="text-right" color="primary" @click="notif = !notif"
                style="position: absolute; right: 10px; top: 10px;">ri-close-circle-fill</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              <h3>Notifikasi</h3>
              <p class="mt-4">Hari ini</p>
            </v-list-item-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="primary">
                  ri-user-search-fill
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>Permintaan Menambah Pengguna Baru</h3>
                </v-list-item-title>

                <v-list-item-subtitle style="white-space:normal;">Pengajuan permintaan menambah pengguna baru atas nama <b>Naela Ali</b> telah disetujui.</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="primary">ri-arrow-right-s-line</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item-title>
              <p class="my-4">Kemarin</p>
            </v-list-item-title>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="primary">
                  ri-checkbox-circle-fill
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>Aktivasi Subdomain Berhasil</h3>
                </v-list-item-title>

                <v-list-item-subtitle style="white-space:normal;">Aktivasi subdomain Anda berhasil. Klik pada link subdomain untuk menuju halaman subdomain Perusahaan Anda.</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="primary">ri-arrow-right-s-line</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-avatar>
                <v-icon color="primary">
                  ri-checkbox-circle-fill
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <h3>Verifikasi Email Berhasil</h3>
                </v-list-item-title>

                <v-list-item-subtitle style="white-space:normal;">Verifikasi email Anda berhasil dilakukan.</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon color="primary">ri-arrow-right-s-line</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-navigation-drawer> -->
        <v-alert 
          v-show="!enabledAuthenticator"
          class="body-2"
          style="
            margin-top: 64px;
            margin-bottom: 0px;
            position: fixed;
            width: 100%;
            z-index: 2;
          "
          type="warning">
          <v-icon>mdi-alert-outline</v-icon>
          <span>Akun Anda belum mengaktifkan Two-Factor Authentication. Aktifkan untuk keamanan aplikasi.</span>
          <router-link to="/profile" class="ml-2 font-weight-bold text-decoration-underline">Aktifkan 2FA Sekarang</router-link>
        </v-alert>
        <v-container
          fluid
          :style="`
            margin-top: ${!enabledAuthenticator ? '120px' : '64px'};
            background-color: #f8fafc;
            min-height: calc(100% - 64px);
          `"
        >
          <div class="mx-2 content-wrapper">
            <transition name="fade-up">
              <router-view
                v-if="loaded"
                @page-changed="pageChanged"
                :key="$route.fullPath"
              ></router-view>
            </transition>
          </div>
        </v-container>
      </v-main>

      <!-- Welcome modal -->
      <v-dialog v-model="show_welcome" width="480px" max-width="100vw">
        <v-card style="position: relative">
          <v-btn
            x-small
            icon
            outlined
            @click="show_welcome = false"
            style="top: 1rem; right: 1rem; position: absolute"
          >
            <v-icon size="16">ri-close-line</v-icon>
          </v-btn>

          <v-row class="px-4 pt-8" no-gutters>
            <v-col cols="12">
              <div class="mb-4 text-center text-h6">
                Selamat Datang di Admin Panel Pensign
              </div>
              <div class="mb-4 text-center">
                Saat ini Anda sudah dapat menikmati fitur - fitur yang ada di
                platform Pensign. <br /><br />
                Disini Anda bisa:<br />
                1. Melihat subdomain Pensign sebagai ruang kerja<br />
                2. Menambahkan/menghapus anggota tim<br />
                3. Membeli kuota ESGN untuk pembubuhan emeterai dan
                penandatanganan digital<br />
                4. Melihat informasi paket langganan<br /><br />
                Ikuti panduan penggunaan layanan dengan klik tombol Jelajahi
                Produk.
              </div>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-row class="pa-4" no-gutters justify="center">
              <v-btn class="mx-2" @click="show_welcome = false"
                >Nanti Saja</v-btn
              >
              <v-btn class="mx-2" color="primary" @click="handleStartTour()"
                >Jelajahi Produk</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-tour name="myTour" :steps="tours">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :step="tour.steps[tour.currentStep]"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :skip="tour.skip"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
            >
              <div slot="actions">
                <div class="d-flex flex-nowrap">
                  <v-btn
                    small
                    text
                    color="white"
                    class="mr-auto"
                    @click="tour.skip"
                  >
                    Tutup
                  </v-btn>

                  <v-btn
                    small
                    v-if="!tour.isFirst"
                    class="ml-2"
                    color="white"
                    @click="tour.previousStep"
                  >
                    Sebelumnya
                  </v-btn>
                  <v-btn
                    small
                    v-if="!tour.isLast"
                    class="ml-2"
                    color="primary"
                    @click="tour.nextStep"
                  >
                    Lanjut
                  </v-btn>
                  <v-btn
                    small
                    v-if="tour.isLast"
                    class="ml-2"
                    color="primary"
                    @click="handleEndTour(tour)"
                  >
                    Akses Sekarang
                  </v-btn>
                </div>
              </div>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </v-app>
  </transition>
</template>

<script>
import menu from './menu';
import transfer from './transfer';
import report from './report';
import contract from './contract';
import setting from './setting';
import receive from './receive';
import history from './history';
import SubscriptionPackage from '@/components/subscription-package.vue';
import { getDomain } from '@/model/new-auth';

export default {
  components: { SubscriptionPackage },
  data() {
    return {
      // enabledAuthenticator: false,
      show_welcome: false,
      domain: '',
      profile_name: '',

      breadcrumbs: [],
      loaded: true,
      drawer: null,
      notifications: [1],
      pageTitle: '',
      ex1: { label: 'color', val: 25, color: 'orange darken-3' },
      attend: false,
      reset: false,
      url: 'https://docs.pensign.id',
      notif: null,
      group: null,
      akun: {},
      roles: {},
    };
  },
  computed: {
    enabledAuthenticator () {
      return this.$store.state.authenticator.enabledAuthenticator;
    },
    statusPartner() {
      return this.akun.status_partner;
    },
    tours() {
      return [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `<div class="text-h6">Atur Anggota Tim</div>
          <div>Untuk mengatur anggota Tim anda bisa masuk melalui menu pengguna</div>`,
          params: {
            placement: 'right',
          },
        },
        {
          target: '[data-v-step="1"]',
          content: `<div class="text-h6">Isi Ulang Saldo</div>
          <div>Untuk membeli saldo ESGN Tim Anda bisa masuk ke menu Inventori</div>`,
          params: {
            placement: 'right',
          },
        },
        // {
        //   target: '[data-v-step="2"]',
        //   content: `<div class="text-h6">Atur Langganan</div>
        //   <div>Untuk mengatur paket langganan Anda dapat melalui menu paket</div>`,
        //   params: {
        //     placement: "right",
        //   },
        // },
        {
          target: '[data-v-step="3"]',
          content: `<div class="text-h6">Tandatangan & Meterai Elektronik</div>
          <div>Mulai penandatanganan digital dengan akses subdomain ruang kerja Anda menggunakan email & kata sandi yang sama saat registrasi</div>`,
          params: {
            placement: 'top',
          },
        },
      ];
    },
    items() {
      return menu;
    },
    trans() {
      return transfer;
    },
    report() {
      return report;
    },
    contra() {
      return contract;
    },
    set() {
      return setting;
    },
    receive() {
      return receive;
    },
    history() {
      return history;
    },
  },
  created() {
    this.$root.$on('setDialog', (attend) => {
      this.attend = attend;
      this.getCounter();
      this.getProfil();
    });
    this.$root.$on('set-page-title', (pageTitle) => {
      this.pageTitle = pageTitle;
    });
    let path = '/' + this.$route.path.split('/')[1];
    let index = this._.findIndex(this.items, { link: path });
    if (index != -1) {
      this.pageTitle = this.items[index].title;
      this.breadcrumbs.push({
        text: this.items[index].title,
        disabled: true,
        href: path,
      });
    }
    this.getCounter();
    this.getProfil();
    this.getAuthenticator();
  },
  mounted() {
    this.$root.$on('updateName', (name) => {
      this.profile_name = name;
    });
    this.getSubDomain();
  },
  methods: {
    getSubDomain() {
      getDomain()
        .then((res) => res.data)
        .then((res) => {
          this.domain = res.data.domain;
        });
      this.axios.get(`v1/auth/dashboard/profile`).then((res) => {
        let data = res.data.data;
        this.profile_name = data.name;
        this.domain = data.domain;
      });
    },
    getCounter() {
      this.axios.get('v1/subscription/count').then((response) => {
        let res = response.data.data;
        this.dataCount = res;
      });
    },
    handleEndTour(tour) {
      tour.stop();
      const a = document.createElement('a');
      a.href = `https://${this.domain}`;
      a.target = '_blank';
      a.click();
      a.remove();
    },
    handleStartTour() {
      this.$tours['myTour'].start();
      this.show_welcome = false;
    },
    navigate() {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = 'https://docs.pensign.id';
      a.click();
    },
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/');
      });
    },
    historyInvoice() {
      this.$router.push('/profile');
    },
    updateLocation(item) {
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      console.log('segment', segment, location);
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }

      document.title =
        this.breadcrumbs
          .slice()
          .reverse()
          .map((val) => val.text)
          .join(' / ') +
        ' - ' +
        process.env.VUE_APP_TITLE;
    },
    getAuthenticator() {
      this.axios.get(`app/dashboard/mfa/authenticator`).then((res) => {
        if(!res.data.data.enabled) return this.$router.push('/profile');
      });
    },
    getProfil() {
      return this.axios.get(`v1/auth/users/profile`).then((res) => {
        this.akun = res.data.data;
        this.roles = this.akun.roles;

        let data = [];
        let id = null;
        if (localStorage.getItem('show_welcome') != null) {
          data = JSON.parse(atob(localStorage.getItem('show_welcome')));
          id = data.find((v) => v.id == this.akun._id);
        }
        if (!(id && id.show_welcome == false)) {
          const user = { id: this.akun._id, show_welcome: false };
          data.push(user);
          localStorage.setItem('show_welcome', btoa(JSON.stringify(data)));
          setTimeout(() => {
            if (this.$route.path == '/dashboard') this.show_welcome = true;
          }, 1e3);
        }
      });
    },
  },
};
</script>

<style>
.fs-1 {
  padding: 0 8px !important;
}
.fs-1 .v-list-item__title {
  font-size: 1rem !important;
  line-height: 1.25 !important;
}
.pointer:hover {
  cursor: pointer;
}
</style>
