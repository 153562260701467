<template>
    <v-card rounded="lg" class="transparent">
        <v-breadcrumbs :items="items" class="px-0">
        <template v-slot:divider>
            <v-icon>ri-arrow-right-s-line</v-icon>
        </template>
        </v-breadcrumbs>
        <v-card-title class="px-0 pt-0 pb-0 text-black fs-20 font-weight-bold">Edit Bulk Disbursement</v-card-title>

        <!-- <template v-if="snackbar === true">
            <v-alert
                v-model="snackbar"
                type="success"
                class="mb-0"
                transition="scale-transition"
                dismissible
            >
                {{ showMessage }}
            </v-alert>
        </template> -->

        <v-card-text class="px-0 pt-0">
            <div class="px-0 content col-md-12">
                <v-form
                ref="form"
                @submit.prevent="submit"
                v-model="valid"
                >
                    <v-card elevation="1">
                        <v-card-title class="font-weight-regular">
                            <div class="row">
                                <div class="col-md-12">
                                <p class="mb-0 text-black fs-16 font-weight-medium">Transaction Info</p>
                                </div>
                            </div>
                        </v-card-title>
                        
                        <div class="table my-3 col-md-12">          
                            <v-row>
                                <v-col cols="5">
                                    <v-subheader class="text-black font-weight-thin fs-14">Disbursement Title</v-subheader>
                                </v-col>
                                <v-col cols="7">
                                    <v-text-field
                                        v-model="serverParams.title"
                                        :rules="[v => !!v || 'Disbursement title is required']"
                                        filled
                                        required
                                        outlined
                                        hide-details="auto"
                                        placeholder="Disbursement Title"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    <v-subheader class="text-black font-weight-thin fs-14">Description</v-subheader>
                                </v-col>
                                <v-col cols="7">
                                    <v-textarea
                                        v-model="serverParams.description"
                                        required
                                        outlined
                                        placeholder="Description"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5">
                                    <v-subheader class="text-black font-weight-thin fs-14">Enable Recurring Bulk Transfer</v-subheader>
                                </v-col>
                                <v-col cols="7">
                                    <v-switch
                                        v-model="serverParams.recurring"
                                        flat
                                    ></v-switch>
                                </v-col>
                            </v-row>
                            <v-row>
                            <v-col cols="5">
                                <v-subheader class="text-black font-weight-thin fs-14">Import Data</v-subheader>
                            </v-col>
                            <v-col cols="7">
                                <div
                                    :class="{'has-file': showRemovedBtn}"
                                    class="c-file-input js-file-input"
                                    @dragover.prevent @drop="onDrop"
                                >
                                    <div class="c-file-input__indicator">
                                        <span class="c-file-input__indicator__icon c-icon c-icon--attach"></span>
                                    </div>
                                    <label class="c-file-input__label js-file-input__label" for="inputfile">
                                    <span>{{ theFileName }}</span>
                                    <input
                                        id="inputfile"
                                        type="file" @change="fileChangeHandler"
                                        name="attachment" class="c-file-input__field js-file-input__field">
                                    </label>
                                </div>
                                <p class="text-black font-weight-thin">Download this <a href="#">excel</a> or <a href="#">csv</a> template</p>
                            </v-col>
                        </v-row>
                        </div>      
                    </v-card>

                    <v-card elevation="1">
                        <v-card-title class="font-weight-regular">
                            <div class="row">
                                <div class="col-md-12">
                                <p class="mb-0 text-black fs-16 font-weight-medium">Recipient</p>
                                </div>
                            </div>
                        </v-card-title>

                        <v-divider></v-divider>

                        <div class="px-3 my-3 row">
                            <div class="col-md-6">
                                <v-btn class="ma-1" @click="handleDelete()">
                                    <v-icon class="mr-2">ri-delete-bin-6-line </v-icon> Delete
                                </v-btn>
                                <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true">
                                    <v-icon class="mr-2">ri-checkbox-circle-line</v-icon> Save as Receiver Group
                                </v-btn>
                            </div>
                            <div class="text-right col-md-6">
                                <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true">
                                    <v-icon class="mr-2">ri-download-cloud-fill</v-icon> Upload Excel/CSV
                                </v-btn>
                            </div>
                        </div>

                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            <v-checkbox
                                                v-model="checkbox"
                                                @click="toggleAll()"
                                            ></v-checkbox>
                                        </th>
                                        <th class="text-left">BANK CODE</th>
                                        <th class="text-left">ACCOUNT NUMBER</th>
                                        <th class="text-left">ACCOUNT NAME</th>
                                        <th class="text-left">AMOUNT</th>
                                        <th class="text-left">EMAIL</th>
                                        <th class="text-left">NOTES</th>
                                        <th class="text-left">ACTIONS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(receipient, idx) in receipients" :key="idx"  class="pb-2">
                                        <td>
                                            <v-checkbox
                                                v-model="receipients[idx].cheklist"
                                                @click="selectedDelete(idx)"
                                            ></v-checkbox>
                                        </td>
                                        <td class="py-2">
                                            <v-select
                                                v-model="receipients[idx].receipient_bank_id"
                                                :rules="[v => !!v || 'Bank name is required']"
                                                :items="getBankName"
                                                item-text="name"
                                                item-value="id"
                                                placeholder="Select bank"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="receipients[idx].receipient_account_number"
                                                :rules="[v => !!v || 'Account number is required']"
                                                type="number"
                                                min="0"
                                                filled
                                                required
                                                outlined
                                                hide-details="auto"
                                                placeholder="Account number"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="receipients[idx].receipient_account_name"
                                                :rules="[v => !!v || 'Account name is required']"
                                                filled
                                                required
                                                outlined
                                                hide-details="auto"
                                                placeholder="Account name"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <!-- <v-text-field
                                                v-model.number="receipients[idx].amount"
                                                :rules="[v => !!v || 'Amount is required']"
                                                type="number"
                                                min="0"
                                                filled
                                                required
                                                outlined
                                                hide-details="auto"
                                                placeholder="Rp. "
                                            ></v-text-field> -->
                                            <MyCurrencyInput v-model="receipients[idx].amount"></MyCurrencyInput>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="receipients[idx].receipient_email"
                                                type="email"
                                                filled
                                                required
                                                outlined
                                                hide-details="auto"
                                                placeholder="Email address"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                v-model="receipients[idx].note"
                                                filled
                                                required
                                                outlined
                                                hide-details="auto"
                                                placeholder="Add note"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-btn
                                                small
                                                dark
                                                @click="deleteRowRecipient(idx, receipient)"
                                                v-if="idx > 0"
                                            >
                                                <v-icon dark>
                                                ri-delete-bin-6-line
                                                </v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <template>
                            <v-btn block large class="my-4" @click.prevent="addNewRowRecipient">
                                <v-icon class="mr-2">ri-add-circle-fill</v-icon> ADD MORE
                            </v-btn>
                        </template>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <div class="mr-5">
                                <p class="mb-0">NUMBER OF RECIPIENT</p>
                                <p class="mb-0 font-weight-bold">{{ receipients.length }}</p>
                            </div>
                            <div>
                                <p class="mb-0">TOTAL AMOUNT</p>
                                <p class="mb-0 font-weight-bold">Rp {{ formatPrice(getTotalAmount) || 0 }}</p>
                            </div>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" outlined class="px-5 ma-1" @click="validateField">
                                VALIDATE
                            </v-btn>
                            <v-btn color="primary" dark class="px-3 ma-1" @click="dialog=true">
                                SUBMIT
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <v-snackbar
                    v-model="isError"
                >
                    {{ showMessage }}
                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="pink"
                            text
                            v-bind="attrs"
                            @click="isError = false"
                        >
                        Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-card-text>

        <v-dialog
        v-model="dialog"
        width="500"
        >
            <v-card>
                <v-card-title class="text-h5">Confirm Bulk Disbursement</v-card-title>

                <v-card-text class="my-3">          
                    <v-simple-table>
                        <template v-slot:default>              
                            <tbody>
                                <tr>
                                    <td>TOTAL AMOUNT</td>
                                    <td>Rp {{ formatPrice(getTotalAmount) }}</td>
                                </tr>
                                <tr>
                                    <td>SEND TO</td>
                                    <td>
                                        <span v-for="(receipient, idx) in receipients" :key="idx" class="text-capitalize">
                                            {{ receipient.receipient_account_name }}
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <!-- <td>NO. REFERENCE</td> -->
                                    <td>TITLE</td>
                                    <td>{{ serverParams.title }}</td>
                                </tr>
                                    <tr>
                                    <td>DESCRIPTION</td>
                                    <td>{{ serverParams.description }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>  
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"            
                        @click="dialog = false"
                        class="text-center"
                        outlined
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"            
                        @click="handleStored"
                        class="text-center"
                        :loading="submiting"
                    >
                        Submit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";
import axios from "axios";

export default {
    name: "bulkDisbursementEdit",
    props: {
        labelPlaceholder: {
            type: String,
            default: 'Add File or drag and drop file here',
        },
    },
    data: () => ({
        items: [
            {
                text: 'E-Transfer',
                disabled: false,
                href: '#',
            },
            {
                text: 'Send Money',
                disabled: false,
                href: '#',
            },
            {
                text: 'Bulk Disbursement',
                disabled: false,
                href: '/bulk-disbursement',
            },
            {
                text: 'Edit Bulk Disbursement',
                disabled: true,
                href: '#',
            },
        ],
        valid: true,
        checkbox: false,
        checkRow: [],
        submiting: false,
        isError: false,
        switch1:false,
        dialog:false,
        snackbar: false,
        showMessage: '',
        serverParams: {
            title: '',
            description: '',
            recurring: false,
        },
        receipients: [{
            id: "",
            receipient_bank_id: "",
            receipient_account_name: "",
            receipient_account_number: "",
            amount: 0,
            receipient_email: "",
            note: "",
            cheklist: false,
        }],
        selectedDeleted: [],
        showRemovedBtn: false,
        files: [],
    }),
    computed: {
        theFileName() {
            if ((this.files.length > 0) && 'name' in this.files[0]) {
                return this.files[0].name;
            }
            return this.labelPlaceholder;
        },
        getBankName() {
            return this.$store.state.sendMoney.getBankName;
        },
        getBankAccount() {
            return this.$store.state.sendMoney.getBankAccount;
        },
        getParam() {
            return { 
                ...this.serverParams,
                receipients: this.receipients
            }  
        },
        getTotalAmount() {
            return this.receipients.reduce((x, y) => x + y.amount, 0);
        },
        idParam: {
            get() {
                return this.$route.params.id;
            }
        }
    },
    beforeCreate: function () {
        this.$options.components.MyCurrencyInput = require('../user/MyCurrencyInput.vue').default
    },
    async mounted() {
        await this.loadBankName();
        await this.loadBankAccount();
        await this.getListBulkDisbursmentDetail2();
    },
    methods: {
        loadBankName() {
            this.$store.dispatch("getBankName");
        },
        loadBankAccount() {
            this.$store.dispatch("getBankAccount");
        },

        getListBulkDisbursmentDetail2() {
            this.isLoading = true;
            return new Promise((resolve, reject) => {
                axios
                .get(`v1/etransfer/disbursement/bulk/receipients/${this.idParam}` )
                .then(response => {
                    this.serverParams.id = this.idParam;
                    this.serverParams.title = response.data.result.detail.title;
                    this.serverParams.description = response.data.result.detail.description;
                    this.serverParams.recurring = response.data.result.detail.recurring;

                    let dataRecepient = response.data.result.receipients.data;
                    this.receipients = [];
                    dataRecepient.forEach((item) => {
                        this.receipients.push({
                            id: item.id,
                            receipient_bank_id: item.receipient_bank_id,
                            receipient_account_name: item.receipient_account_name,
                            receipient_account_number: item.receipient_account_number,
                            amount: parseInt(item.amount),
                            receipient_email: item.email,
                            note: item.note,
                        });
                    })
                    this.isLoading = false;
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    this.isLoading = false;
                });
            });
        },
        handleStored() {
            // console.log('hasil', this.getParam);
            setTimeout(() => {
                if (this.$refs.form.validate()) {
                    this.submiting = true;
                    this.axios
                    .post(
                        `v1/etransfer/disbursement/bulk/edit`, this.getParam,
                        {
                        headers: {"Content-Type": "application/json"},
                        })
                    .then((response) => {
                        this.isError = true;
                        this.showMessage = response.data.message
                        this.submiting = false;
                        // this.$refs.form.reset();
                        // this.dialog = false;
                        setTimeout( () => {
                            this.$router.push({ path: '/bulk-disbursement' })
                            // this.$router.push({ name: 'bulk-disbursement' })
                        }, 1500);
                    })
                    .catch((error) => {
                        this.dialog = false;
                        this.submiting = false;   
                        this.isError = true;   
                        this.showMessage = error.response.data.message;
                    });

                    // this.receipients.splice(1, this.receipients.length - 1);
                    // this.snackbar = false;
                }
            }, 50);
        },

        addNewRowRecipient() {
            this.receipients.push({
                id: "",
                receipient_bank_id: "",
                receipient_account_name: "",
                receipient_account_number: "",
                amount: 0,
                receipient_email: "",
                note: "",
            });
        },
        deleteRowRecipient(index, receipient) {
            var idx = this.receipients.indexOf(receipient);
            if(this.selectedDeleted.includes(idx)) {
                let idRow = this.selectedDeleted.indexOf(idx);
                for (let x = idRow + 1; x < this.selectedDeleted.length; x++) {
                    this.selectedDeleted[x] = this.selectedDeleted[x] - 1;
                    // console.log('deleteRowRecipient', x);
                }
                this.selectedDeleted.splice(idRow, 1);
            }
            if (idx > -1) {
                this.receipients.splice(idx, 1);
            }
        },

        selectedDelete(id) {
            if(this.selectedDeleted.includes(id)) {
                this.selectedDeleted.splice(this.selectedDeleted.indexOf(id), 1)
            } else {
                this.selectedDeleted.push(id);
            }
        },
        toggleAll() {
            if(this.checkbox === true) {
                for (let x = 0; x < this.receipients.length; x++) {
                    this.receipients[x].cheklist = true;
                    this.selectedDeleted.push(x);
                }
            } else {
                for (let x = 0; x < this.receipients.length; x++) {
                    this.receipients[x].cheklist = false;
                    this.selectedDeleted.splice(this.selectedDeleted.indexOf(x), 1)
                }
            }
        },
        handleDelete() {
            this.selectedDeleted.sort();
            for (let x = 0; x < this.selectedDeleted.length; x++) {
                if(this.receipients.length !== 0) {
                    // console.log('handleDelete', this.receipients.length, this.selectedDeleted[x], x);
                    this.receipients[0].cheklist = false;
                    this.receipients.splice(this.selectedDeleted[x] - x, 1);
                }
            }
            this.checkbox = false;
            this.selectedDeleted = [];
        },

        onDrop: function(e) {
            e.stopPropagation();
            e.preventDefault();
            var files = e.dataTransfer.files;
            this.fileChangeHandler(files[0]);
        },
        fileChangeHandler(e) {
            this.files = Array.from(e.target.files);
            this.showRemovedBtn = true;
        },
        clearFileHandler() {
            const el = this.$el.querySelector('.js-file-input__field');
            const wrapper = document.createElement('form');
            this.wrapInputAndReset(el, wrapper);
        },
        wrapInputAndReset(el, wrapper) {
            // wrap input with form tag
            el.parentNode.insertBefore(wrapper, el);
            wrapper.appendChild(el);

            // reset input with form.reset()
            wrapper.reset();

            // place childNodes in document fragment
            const docFrag = document.createDocumentFragment();
            while (wrapper.firstChild) {
                const child = wrapper.removeChild(wrapper.firstChild);
                docFrag.appendChild(child);
            }

            // replace wrapper with document fragment
            wrapper.parentNode.replaceChild(docFrag, wrapper);

            this.files = [];
            this.showRemovedBtn = false;
        },

        validateField() {
            this.$refs.form.validate();
        },
        formatPrice(value) {
            return formatPrice(value);
        },
    },
};
</script>

<style>
</style>