<template>
    <div>
       <v-dialog
          v-model="dialog_update"
          fullscreen
          persistent
        >
          <v-card>
            <v-card-text>
              <br><br><br><br><br><br><br>
              <v-form
                ref="form"
                @submit.prevent="submit"
                v-model="valid"
                class="mx-auto rounded-lg col-md-4 elevation-2"            
              >
                <p class="text-h4">Atur Kata Sandi untuk Pertama Kali</p>
                <p class="text-subtitle-1">Silahkan atur kata sandi anda untuk pertama kalinya</p>
                <v-container>
                  <v-alert
                    class="mt-2"
                    type="error"
                    text
                    dense
                    prominent
                    v-show="response != null"
                    >{{ response }}</v-alert
                  >
                  <v-alert
                    class="mt-2"
                    type="success"
                    text
                    dense
                    prominent
                    v-show="responseS != null"
                    >
                    {{ responseS }}.<br>
                    Tunggu beberapa saat Anda akan di arahkan ke halaman Beranda

                    </v-alert
                  >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <div class="mt-1 mb-2 text-black subtitle-2">Kata sandi</div>                      
                      <v-text-field
                        v-model="password"
                        :rules="pswRules"
                        required
                        outlined
                        hide-details="auto"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show = !show"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <div class="mt-1 mb-2 text-black subtitle-2">Konfirmasi kata sandi</div>                      
                      <v-text-field
                        v-model="confirm_password"
                        :rules="cnfrmPswRules"
                        outlined
                        hide-details="auto"
                        required
                        :type="show2 ? 'text' : 'password'"
                        :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-btn
                        color="primary"
                        dark block
                        @click="submit()"
                        :loading="submiting"
                      >
                        Simpan
                      </v-btn>                      
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
  data() {
    return {
      dialog_update:true,
      valid: true,
      errors: null,      
      submiting: false,
      response:null,
      responseS:null,
      show:false,
      show2:false,
      password:'',
      confirm_password:'',
      pswRules: [
        (v) => !!v || "Kata sandi tidak boleh kosong",
        (v) => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
          return pattern.test(v) || "Password harus memiliki setidaknya 8 karakter dan memiliki 1 huruf besar, 1 huruf kecil, 1 simbol, serta 1 angka.";
        },
      ],
    }
  },
  computed: {
    validate() {
      return !this.errors || this.errors.message;
    },
    cnfrmPswRules() {
      return [
        () =>
          this.password === this.confirm_password ||
          "Konfirmasi kata sandi tidak sama",
        (v) => !!v || "Konfirmasi kata sandi tidak boleh kosong",
      ];
    },
  },
  methods: {
        submit() {      
        this.submiting = true;
      setTimeout(() => {
        if (this.$refs.form.validate()) {
          let data = { 
            password:this.password,
            password_confirm:this.confirm_password
          };

          this.axios
            .post(
              // `auth/users/reset_password`,data,
              `v1/auth/update_password`,data,
              {
                headers: {"Content-Type": "application/json"},
              })
            .then((res) => {
              // console.log(res)
              if (res.data.code ===200) {              
                this.submiting = false;
                this.responseS = res.data.msg;              
                setTimeout( () => {                
                  this.responseS=null;
                  this.$router.push("/dashboard");                
                }
                , 3000);            
              }else{
                this.submiting = false;              
                this.response = res.data.msg;
                setTimeout( () => {                
                  this.response=null;
                }
                , 2000);
              }
            })
            .catch((error) => {
              this.submiting = false;
              this.$refs.form.reset();
              // console.log('er',error.response)            
              this.response = error.response.data.message;
              setTimeout( () => {                
                this.response=null;
              }
              , 3000);
            });
        }
        else{
          this.submiting = false;
        } 
      } , 50);
      
    },
  }
}
</script>

<style scoped>
    /* input {
        border: 1px solid #888;
        font-size: 1.2rem;
        padding: 0.5rem;
    } */
</style>