<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Need Validation Detail</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="row mb-5 mt-5 white">
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DISBURSEMENT TITLE</p>
                <p class="fs-16 text-black font-weight-medium">{{ dataDetail.title }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DESCRIPTION</p>
                <p class="fs-16 text-black font-weight-medium">{{ dataDetail.description }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">INPUT BY</p>
                <p class="fs-16 text-black font-weight-medium"><span class="text-capitalize">{{ dataDetail.creator_user_name }}</span> on {{ dataDetail.approved_at }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">APPROVED BY</p>
                <!-- <p class="fs-16 text-black font-weight-medium text-capitalize"><v-icon color="#FFC234">ri-error-warning-fill</v-icon> {{ dataDetail.status }}</p> -->
                <p class="fs-16 text-black font-weight-medium text-capitalize"><v-icon color="#FFC234">ri-error-warning-fill</v-icon> {{ dataDetail.approved_user_name }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">STATUS</p>
                <p class="fs-16 text-black font-weight-medium">
                  <v-chip
                    class="text-uppercase"                    
                    color="#FF6F00"
                    outlined
                  >
                    <!-- Pending Validation -->
                    {{ dataDetail.status }}
                  </v-chip></p>
              </v-card-text>
            </v-card>
          </div>
        </div>
        
        <div class="table col-md-12 px-0">          
          <v-card class="px-2" elevation="1">            
            <div class="row mb-3">
              <div class="col-md-6 align-middle">
                <p class="fs-14 text-black font-weight-medium mt-2 ps-2">List of Transaction</p>                
              </div>
              <!-- <div class="col-md-6 text-right">
                <v-btn color="primary" class="white--text ma-1">
                  <v-icon class="mr-2">ri-checkbox-circle-line</v-icon> Submit For Approval
                </v-btn>
              </div> -->
              <div class="col-md-12">
                <v-divider></v-divider>
              </div>
              <div class="col-md-6">
                <p class="text-black fs-14"><v-icon class="mr-2" color="#FF3434">ri-error-warning-fill</v-icon> Transaction with issues (3)</p>           
              </div>
              <div class="col-md-6 text-right">
                <v-btn class="ma-1" @click="deleteAllItem">
                  <v-icon class="mr-2">ri-delete-bin-6-line </v-icon> Delete
                </v-btn> 
                <v-btn color="grey darken-4" class="white--text ma-1" @click="handleValidate" :loading="isLoading">
                  Validate
                </v-btn>                  
              </div>
            </div>

            <v-data-table
              v-model="selectedRows"
              :headers="headers"
              :items="dataDetailTransaction"
              :single-select="singleSelect"
              item-key="id"
              show-select
              class="elevation-1"
              @input="rowSelected()"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.icon`]="{ item }">
                <v-icon
                  v-if="item.icon === 'error'"
                  small
                  class="mr-2"
                  color="#FF3434"
                >
                  ri-error-warning-fill
                </v-icon>
                <v-icon
                  v-else
                  small
                  class="mr-2"
                  color="success"
                >
                  ri-checkbox-circle-line
                </v-icon>
              </template>

              <!-- FORM -->
              <template v-slot:[`item.receipient_bank_name`]="{ item }">
                <!-- <v-text-field v-model="editedItem.receipient_bank_name" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field> -->
                <v-select
                  v-model="editedItem.receipient_bank_name"
                  v-if="item.id === editedItem.id"
                  :rules="[v => !!v || 'Bank name is required']"
                  :items="getBankName"
                  item-text="name"
                  item-value="id"
                  placeholder="Select bank"
                ></v-select>
                <span v-else>{{ item.receipient_bank_name }}</span>
              </template>

              <template v-slot:[`item.receipient_account_number`]="{ item }">
                <v-text-field v-model="editedItem.receipient_account_number" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                <span v-else>{{ item.receipient_account_number }}</span>
              </template>

              <template v-slot:[`item.receipient_account_name`]="{ item }">
                <v-text-field v-model="editedItem.receipient_account_name" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                <span v-else>{{ item.receipient_account_name }}</span>
              </template>

              <template v-slot:[`item.amount`]="{ item }">
                <v-text-field v-model="editedItem.amount" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                <span v-else>{{ formatPrice(item.amount) }}</span>
              </template>

              <template v-slot:[`item.email`]="{ item }">
                <v-text-field v-model="editedItem.email" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                <span v-else>{{ item.email || '-' }}</span>
              </template>

              <template v-slot:[`item.note`]="{ item }">
                <v-text-field v-model="editedItem.note" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                <span v-else>{{ item.note || '-' }}</span>
              </template>

              <!-- <template v-slot:[`item.action`]="{ item }">
                <div v-if="item.id === editedItem.id" class="d-flex">
                  <v-icon class="mr-3" @click="close">
                    ri-close-fill
                  </v-icon>
                  <v-icon @click="save">
                    ri-check-fill
                  </v-icon>
                </div>
                <div v-else class="d-flex">
                  <v-icon class="mr-3" @click="editItem(item)">
                    ri-pencil-fill
                  </v-icon>
                  <v-icon @click="deleteItem(item)">
                    ri-delete-bin-6-fill
                  </v-icon>
                </div>
              </template> -->

              <!-- ACTION -->
              <!-- <template v-slot:[`item.action`]="{ item }">
                <template v-if="!isEditDelete">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editSelected(item)"
                  >
                    ri-pencil-fill
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteSelected(item)"
                  >
                    ri-delete-bin-6-fill
                  </v-icon>
                </template>

                <template v-else>
                  <v-icon
                    small
                    class="mr-2"
                    @click="closeSelected(item)"
                  >
                    ri-close-fill
                  </v-icon>
                  <v-icon
                    small
                    @click="submitSelected(item)"
                  >
                    ri-check-fill
                  </v-icon>
                </template>
              </template> -->
              
            </v-data-table>
          </v-card>

          <v-snackbar
            v-model="isError"
          >
            {{ showMessage }}
            <template v-slot:action="{ attrs }">
              <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="isError = false"
              >
                Close
              </v-btn>
            </template>
          </v-snackbar>
        </div>        
      </div>
    </v-card-text>    
  </v-card>
</template>

<script>
import { formatPrice } from "@/lib/formatDate";
import axios from "axios";

export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Bulk Disbursement',
        disabled: false,
        href: '/bulk-disbursement?tab=need-validation',
      },
      {
        text: 'Need Validataion Detail',
        disabled: true,
        href: '#',
      },
    ],
    headers: [
      { text: '', value: 'icon' },
      { text: 'BANK CODE', value: 'receipient_bank_name' },
      { text: 'ACCOUNT NO.', value: 'receipient_account_number' },
      { text: 'ACCOUNT NAME', value: 'receipient_account_name' },
      { text: 'AMOUNT', value: 'amount' },
      { text: 'EMAIL', value: 'email' },
      { text: 'NOTES', value: 'note' },
      // { text: 'ACTION', value: 'action' },
    ],
    singleSelect: false,
    selectedRows: [], 
    dataDetail: {},
    dataDetailTransaction: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      receipient_bank_name: '',
      receipient_account_number: 0,
      receipient_account_name: '',
      amount: 0,
      email: '',
      note: '',
    },
    defaultItem: {
      id: 0,
      receipient_bank_name: '',
      receipient_account_number: 0,
      receipient_account_name: '',
      amount: 0,
      email: '',
      note: '',
    },
    showMessage: '',
    isError: false,
    isLoading: false,
    serverParams: {
      id: "",
      type: ""
    }
  }),
  mounted() {
    this.getListBulkDisbursmentDetail2();
    this.loadBankName();
  },
  computed: {
    getBankName() {
      return this.$store.state.sendMoney.getBankName;
    },
    idParam: {
      get() {
        return this.$route.params.id;
      }
    },
  },
  methods: {
    getListBulkDisbursmentDetail2() {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
          axios
          .get(`v1/etransfer/disbursement/bulk/receipients/${this.idParam}` )
          .then(response => {
            this.dataDetail = response.data.result.detail;
            this.dataDetailTransaction = response.data.result.receipients.data;
            this.serverParams.id = this.dataDetail.id;
            if(this.dataDetail.type == "bulk") {
              this.serverParams.type = this.dataDetail.type;
            } else {
              this.serverParams.type = "";
            }
            this.serverParams.type = this.dataDetail.type;
            this.isLoading = false;
            resolve(response);
          })
          .catch(error => {
            reject(error);
            this.isLoading = false;
          });
      });
    },

    handleValidate() {
      setTimeout(() => {
        this.isLoading = true;
        this.$store.dispatch("validateNeedValidation", this.serverParams)
        .then((response) => {
          this.isError = true;
          this.showMessage = response.data.message;
          this.isLoading = false;
          setTimeout( () => {
            this.$router.push({path: '/bulk-disbursement', query: { tab: 'need-approval' }})
          }, 1000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.isError = true;
          this.showMessage = error.response.data.message;
        });
      }, 50);
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.dataDetailTransaction[this.editedIndex], this.editedItem);
        console.log('object',this.editedItem);
      }
      this.close()
    },
    close () {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300)
    },
    editItem (item) {
      this.editedIndex = this.dataDetailTransaction.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    deleteItem (item) {
      const index = this.dataDetailTransaction.indexOf(item);
      confirm('Are you sure you want to delete this item?') && this.dataDetailTransaction.splice(index, 1);
    },

    loadBankName() {
      this.$store.dispatch("getBankName");
    },

    rowSelected() {
      console.log(this.selectedRows.map(e => e.id));
      if (this.selectedRows.length == this.dataDetailTransaction) {
        alert('selectedRows all')
      }
    },
    deleteAllItem () {
      for(var i = 0; i < this.selectedRows.length; i++){
        const index = this.dataDetailTransaction.indexOf(this.selectedRows[i]);
        console.log('object',index);
        this.dataDetailTransaction.splice(index, 1);
      }
      this.dialog = false
    },

    // editSelected(item) {
    //   if(item.id) {
    //     console.log(this.selectedRows);
    //     this.isEditDelete = true;
    //   }
    // },
    // closeSelected(item) {
    //   console.log(item.id)
    //   this.isEditDelete = false;
    // },
    // deleteSelected(item) {
    //   alert(item.id)
    // },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
  .v-chip {
    max-width: inherit !important;
  }
</style>