var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent",attrs:{"rounded":"lg"}},[_c('v-card-title',{staticClass:"px-0 pt-0 text-black fs-20 font-weight-bold"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"mx-auto text-center col-md-6"},[_c('p',{staticClass:"px-0 pt-0 pb-0 mb-0 text-black text-h4"},[_vm._v("Paket Subskripsi Anda")])])])]),_c('v-card',{staticClass:"pa-2 ma-3",attrs:{"elevation":"2"}},[_c('v-card-text',{staticClass:"pb-2"},[_c('h1',{staticClass:"activate-complete__message-title"},[_vm._v(" Paket Anda ")]),_c('p',{staticClass:"activate-complete__message-subtitle"},[_vm._v(" "+_vm._s(_vm.dataCount.name)+" - "+_vm._s(_vm.dataCount.max_user)+" user ")]),_c('p',[_vm._v("Berlaku Hingga: "+_vm._s(_vm.formatToDate(_vm.dataCount.duration_package)))])])],1),_c('v-card-text',{staticClass:"px-0"},[_c('div',[_c('div',{staticClass:"table"},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-regular"},[_vm._v(" Riwayat Invoice ")]),(!_vm.invoice)?_c('div',{staticClass:"mx-auto my-2 text-center col-md-6"},[_c('img',{attrs:{"src":require("@/assets/images/empty.png")}}),_c('p',{staticClass:"mb-2 text-black font-weight-bold"},[_vm._v("No data available.")])]):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoice,"search":_vm.search,"loading":_vm.isLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.external_id",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.extra.invoice_url,"target":"_blank"}},[_vm._v(_vm._s(item.external_id))])]}},{key:"item.package_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order_datetime)+" ")]}},{key:"item.plan_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan_name)+" ")]}},{key:"item.plan_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.plan_price)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'EXPIRED')?_c('div',[_c('v-chip',{staticClass:"my-3 text-center",attrs:{"color":"warning","small":""}},[_vm._v(" "+_vm._s(item.exp)+" ")])],1):(item.status === 'PENDING')?_c('div',[_c('v-chip',{staticClass:"my-3 text-center",attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('p',{staticClass:"mt-0 text-red"},[_vm._v(" "+_vm._s(item.exp)+" ")])],1):_c('v-chip',{staticClass:"my-3 text-center",attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-underline",attrs:{"to":{ name: 'detail-subscription', params: { id: item.id } }}},[_vm._v("Invoice")])]}}],null,true)})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.isError = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.isError),callback:function ($$v) {_vm.isError=$$v},expression:"isError"}},[_vm._v(" "+_vm._s(_vm.showMessage)+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }