import axios from "axios";
import url from "./api";

const state = () => {
  return {
    enabledAuthenticator: false,
  };
}

const mutations = {
  SET_AUTHENTICATOR(state, value) {
    state.enabledAuthenticator = value;
  },
};

const getters = {
  getEnabledAuthenticator: (state) => state.enabledAuthenticator,
};

const actions = {
  async getAuthenticator({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(url.setup_authenticator, { headers: { "x-access-token": JSON.parse(localStorage.getItem('pensign_auth')).token } })
        .then((res) => res.data)
        .then((res) => {
          commit("SET_AUTHENTICATOR", res.data.enabled);
          resolve(res);
        })
        .catch((error_code) => {
          reject(error_code);
        });
    });
  },
};

export default {
  // namespaced: true,
  state,
  mutations,
  getters,
  actions,
};