<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Scheduled Disbursement Detail</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="align-end text-end">          
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="grey darken-4"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Action <v-icon>ri-arrow-drop-down-fill</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>Edit  Scheduled Disbursement</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Update  Scheduled Disbursement</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Pause  Scheduled Disbursement</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Cancel  Scheduled Disbursement</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="row mb-5 mt-5 white">          
          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DESCRIPTION</p>
                <p class="fs-16 text-black font-weight-medium text-capitalize">{{ dataDetail.description }}</p>
              </v-card-text>
            </v-card>
          </div>

          <v-divider vertical></v-divider>

          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">DATE STARTED</p>
                <p class="fs-16 text-black font-weight-medium">{{ formatDate(dataDetail.created_at) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">NEXT DISBURSEMENT</p>
                <template v-if="dataDetail.recurring_date === null">
                  -
                </template>
                <template v-else>
                  <p class="fs-16 text-black font-weight-medium">{{ formatDate(dataDetail.approved_at) }}</p>
                </template>
              </v-card-text>
            </v-card>
          </div>
          <v-divider vertical></v-divider>          
          <div class="col-md-2 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">RECURRING PERIOD</p>
                <template v-if="dataDetail.recurring_periode === null">
                  -
                </template>
                <template v-else>
                </template>
                  <p class="fs-16 text-black font-weight-medium text-capitalize">{{ dataDetail.recurring_periode }}</p>
              </v-card-text>
            </v-card>
          </div>

          <v-divider vertical></v-divider>

          <div class="col-md-3 px-1">
            <v-card rounded="lg" class="transparent px-0">
              <v-card-text class="py-5">
                <p class="text-semu fs-12">TOTAL AMOUNT</p>
                <p class="fs-16 text-black font-weight-medium">Rp {{ formatPrice(dataDetail.total_amount) }}</p>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="table col-md-12 px-0">          
          <v-card class="px-2" elevation="1">            
            <div class="row mb-3">
              <div class="col-md-6 align-middle">
                <p class="fs-14 text-black font-weight-medium mt-2 ps-2 mb-0">List of Transaction</p>                
              </div>
              <div class="col-md-6 text-right">
                
              </div>
              <div class="col-md-12">
                <v-divider></v-divider>
              </div>
              <div class="col-md-6">
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="$search"
                  class="mt-1 ms-2 mx-width-85"
                  placeholder="Search"
                  solo
                  flat
                ></v-text-field>           
              </div>
              <div class="col-md-6 text-end align-end">
                <v-select
                  :items="ex"
                  placeholder="Sort by"
                  class="ma-1 float-right"
                  style="width: 130px;"
                ></v-select>                  
              </div>
            </div>
            
            <v-data-table
              :headers="headers"
              :items="dataDetailTransaction"
              :search="search"
              item-key="no"
              class="elevation-1"
            >              
            </v-data-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>    
  </v-card>
</template>

<script>
import axios from "axios";
import { formatPrice, formatDate } from "@/lib/formatDate";

export default {
  data: () => ({
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Send Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Scheduled Disbursement',
        disabled: false,
        href: '/schedule-disbursement',
      },
      {
        text: 'Scheduled Disbursement Detail',
        disabled: true,
        href: '#',
      },
    ],
    headers: [
      { text: 'BANK CODE', value: 'recepient_bank_name' },
      { text: 'ACCOUNT NO.', value: 'recepient_account_number' },
      { text: 'ACCOUNT NAME', value: 'recepient_account_name' },
      { text: 'AMOUNT', value: 'amount' },
      { text: 'EMAIL', value: 'email' },
      { text: 'NOTES', value: 'note' },
    ],
    desserts: [
      {
        code:'BJB',
        no:'60722328928',
        name:'Esther Howard',
        amount:'Rp 20.000.000',
        email:'lparisian@medhurst.com',
        note:'Velit officia conse ...',            
      },
      {
        code:'BCA',
        no:'607223289528',
        name:'Esther Howard',
        amount:'Rp 20.000.000',
        email:'lparisian@medhurst.com',
        note:'Velit officia conse ...',  
      },
    ],
    search: '',
    ex: ['1','2','3'],
    singleSelect: false,
    selected: [],    
    dataDetail: {},
    dataDetailTransaction: [],
  }),
  mounted() {
    this.getListScheduleDetail();
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    idParam: {
      get() {
        return this.$route.params.id;
      }
    }
  },
  methods: {
    getListScheduleDetail() {
      return new Promise((resolve, reject) => {
        axios
        .get(`v1/etransfer/schedule/receipients/${this.idParam}` )
        .then(response => {
          this.dataDetail = response.data.result.detail;
          this.dataDetailTransaction = response.data.result.receipients.data;
          resolve(response);
        })
        .catch(error => {
            reject(error);
        });
      });
    },

    formatDate(value) {
      return formatDate(value);
    },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
</style>