<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold pb-0">Invoice Detail</v-card-title>
    <p class="text-semu font-weight-medium fs-16 pt-0">Invoice No. {{ dataDetail.code }}</p>

    <v-card-text class="px-0">
      <div class="content">
        <div class="row mb-5">
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu text-uppercase">Transaction Amount</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(dataDetail.amount) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu text-uppercase">ADMIN Fees</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(dataDetail.unique_code) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu text-uppercase">Received Amount</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(dataDetail.total_amount) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu text-uppercase">TRANSACTION STATUS</p>
                <p class="fs-18 mb-0 text-uppercase">
                  <v-chip
                  color="success"
                  outlined                  
                >
                  {{ dataDetail.status }}
                </v-chip>
                </p>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-6">
                  <p class="fs-16 text-black font-weight-medium">Summary</p>
                </div>
                <div class="col-md-6 text-right">
                  <!-- <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true">
                    <v-icon class="mr-2">ri-download-cloud-fill</v-icon> PDF Invoice
                  </v-btn> -->

                  <a href="https://pay.pensign.id/invoice/2131121" target="_blank" class="white--text ma-1 v-btn v-btn--has-bg v-btn--router theme--light elevation-0 v-size--default grey darken-4">
                    <v-icon class="mr-2" style="color:white;">ri-download-cloud-fill</v-icon> PDF Invoice
                  </a>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <v-divider></v-divider>
              </div>              
            </v-card-title>

            <div class="row mb-3">
              <div class="col-md-6">                
                <v-simple-table class="fs-14">
                  <template v-slot:default>                  
                    <tbody>
                      <tr>
                        <td class="border-0">Billed to</td>
                        <td class="text-black border-0 font-weight-medium text-capitalize">{{ dataDetail.fullname }}</td>
                      </tr>
                      <tr>
                        <td class="border-0">Email Address</td>
                        <td class="text-black border-0 font-weight-medium">{{ dataDetail.email }}</td>
                      </tr>
                      <tr>
                        <td class="border-0">Phone Number</td>
                        <td class="text-black border-0 font-weight-medium">{{ dataDetail.mobilephone }}</td>
                      </tr>
                      <tr>
                        <td class="border-0">Description</td>
                        <td class="text-black border-0 font-weight-medium text-capitalize">{{ dataDetail.description }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
              <div class="col-md-6">                
                <v-simple-table class="fs-14">
                  <template v-slot:default>                  
                    <tbody>
                      <tr>
                        <td class="border-0">Issued Date</td>
                        <td class="text-black border-0 font-weight-medium">{{ dataDetail.createdAt }}</td>
                      </tr>
                      <tr>
                        <td class="border-0">Due Date</td>
                        <td class="text-black border-0 font-weight-medium">{{ dataDetail.due_date }}</td>
                      </tr>
                      <tr>
                        <td class="border-0">Request Transfer Date</td>
                        <td class="text-black border-0 font-weight-medium">{{ dataDetail.createdAt }}</td>
                      </tr>
                      <tr>
                        <td class="border-0">Fund Source</td>
                        <td class="text-black border-0 font-weight-medium">{{ dataDetail.payment_method }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>

            <div class="col-md-12 px-4">
              <v-divider></v-divider>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <v-simple-table class="fs-14 px-4">
                  <template v-slot:default>       
                    <thead>
                      <tr>
                        <th>ITEM</th>
                        <th class="text-center">QTY</th>
                        <th class="text-right">PRICE</th>
                        <th class="text-right">TOTAL</th>
                      </tr>
                    </thead>           
                    <tbody>
                      <tr v-for="(data, idx) in dataDetail.item" :key="idx">
                        <template v-if="idx !== 0">
                          <td class="text-capitalize">{{ data.name }}</td>
                          <td class="text-center">{{ data.qty }}</td>
                          <td class="text-right">Rp {{ formatPrice(data.price) }}</td>
                          <td class="text-right">Rp {{ formatPrice(data.total_price) }}</td>
                        </template>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3" class="text-right">Kode Unik</td>
                        <td class="text-right">
                          <template v-if="dataDetail.unique_code === null">
                            -
                          </template>
                          <template>
                            {{ dataDetail.unique_code }}
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3" class="font-weight-bold text-right">Total Amount</td>
                        <td class="font-weight-bold text-right">Rp {{ formatPrice(dataDetail.total_amount) }}</td>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </div>
            </div>

            <div class="col-md-12 px-4">
              <v-divider></v-divider>
            </div> 
            <v-row>
              <v-col cols="3">
                <v-subheader class="text-black font-weight-thin fs-14">Request Transfer Page Link</v-subheader>
              </v-col>
              <v-col cols="9">
                <p class="mt-3">                  
                  <a :href="dataDetail.link" target="_blank">{{ dataDetail.link }}</a>
                  <v-icon>ri-file-copy-fill</v-icon>
                </p>
              </v-col>
            </v-row>            
          </v-card>
        </div>        
      </div>
    </v-card-text>
    
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="dialog=false"
        >
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5">
          Export Transaction History Report
        </v-card-title>

        <v-card-text class="my-3">
          <p class="mb-0">Select File Type</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { formatPrice, formatDate } from "@/lib/formatDate";

export default {
    data: () => ({
      dialog:false,
      menu:false,
      items: [
        {
          text: 'E-Transfer',
          disabled: false,
          href: '#',
        },
        {
          text: 'Receive Money',
          disabled: false,
          href: '#',
        },
        {
          text: 'Invoice',
          disabled: false,
          href: '/invoice',
        },
        {
          text: 'Invoice Detail',
          disabled: true,
          href: '#',
        }
      ],
      ex: ['1','2','3'],
      search: '',
      dates:[],
      dataDetail: {},
    }),
  mounted() {
    this.getListInvoiceDetail();
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    idParam: {
      get() {
        return this.$route.params.id;
      }
    }
  },
  methods: {
    getListInvoiceDetail() {
      return new Promise((resolve, reject) => {
        axios
        .get(`v1/etransfer/invoice/${this.idParam}` )
        .then(response => {
          this.dataDetail = response.data.data;
          // console.log('object', this.dataDetail);
          resolve(response);
        })
        .catch(error => {
            reject(error);
        });
      });
    },

    formatDate(value) {
      return formatDate(value);
    },
    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
</style>