<template>
  <v-card rounded="lg" class="transparent">
    <!-- <v-card-title class="px-0 pt-0 pb-0 text-black fs-20 font-weight-bold">Subskripsi</v-card-title> -->

    <v-card-text class="px-0">
      <div class="mt-5">
        <div class="table">
          <!-- <div class="row">
            <div class="col-12">
              <div class="card-header__item">
                <div class="card-header__card" elevation="2">
                  <h3 class="card-header__card-title">SALDO TERSEDIA</h3>
                  <h4 class="mt-2 card-header__card-subtitle">
                    RP. {{ formatToPrice(deposit_balance) }}
                  </h4>
                </div>
              </div>
            </div>
          </div> -->

          <v-card class="pb-1 mt-5">
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="mx-auto text-center pa-3">
                  <p class="mb-0 text-black fs-20 font-weight-medium">Pilih Paket Subskripsi Anda</p>
                </div>
                <!-- <div class="text-right col-md-6">
                  <v-btn color="primary" class="white--text ma-1" large :to="{ name: 'inventory-products'}">
                    BELI PRODUK LENGKAP
                  </v-btn>
                </div> -->
              </div>
            </v-card-title>

            <v-divider></v-divider>

          <div class="col-md-12">
            <div class="row">
              <div v-for="(pricing, index) in pricings" :key="index" class="col-md-3">
                <div class="pricing-card">
                    <div class="pricing-card-header" style="justify-content: left!important;">
                      <div>
                        <h1 class="pricing-card-header__title text-h5">
                          {{ pricing.name }}
                        </h1>
                      </div>
                    </div>
                    <div class="mb-5 pricing-card-feature">
                      <template>
                        <h2 class="text-left card-header__card-subtitle-h2 text-h4">
                          IDR  {{ pricing.price }} <span style="color: #1E293B;font-size: 14px;font-weight: 400;text-transform: lowercase;"> / {{ pricing.type }}</span>
                        </h2>
                      </template>                      
                    </div>
                    <v-btn
                      class="mb-5 pricing-card-action__button"
                      width="70%"
                      height="95px"
                      color="primary"
                      @click="checkout(pricing.id)"
                    >
                      Pilih Paket
                    </v-btn>
                    <div class="text-left box-list-fitur">
                      <ul type="none">
                        <li v-for="(item, index) in pricing.description" :key="'A'+index" class="mb-2">
                          <v-icon>ri-checkbox-circle-line</v-icon> {{item}}
                        </li>
                      </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
          </v-card>
        </div>
      </div>
    </v-card-text>
    <v-snackbar v-model="notification.state" top color="primary" outlined>
      {{ notification.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
  import {reactive, toRefs, onMounted} from "@vue/composition-api";
  import {formatPrice} from "../../lib/formatDate";
  import { mapGetters, mapActions } from 'vuex';
  import axios from 'axios';

  // const pricing = [
  //   {
  //     id: 1,
  //     img: "inventory",
  //     title: "e-Meterai",
  //     price: "1.180",
  //     type: "emet",
  //     action: 'Beli Bundling'
  //   },
  //   {
  //     id: 2,
  //     img: "api-seeting",
  //     title: "e-Signature",
  //     price: "2.200",
  //     type: "esgn",
  //     action: 'Beli Bundling'
  //   },
  //   {
  //     id: 3,
  //     img: "api-seeting",
  //     title: "e-Stamp",
  //     price: "2.430",
  //     type: "estp",
  //     action: 'Beli Bundling'
  //   },
  // ];

  export default {
    setup() {
      const state = reactive({
        pricings: null,
        isLoading: true
      });

      const formatToPrice = (value) => {
        return formatPrice(value);
      };

      onMounted(async ()=>{
        state.isLoading = true;
        let { data } = await axios.get("/v1/subscription/plan")
        state.pricings = data.data
        state.isLoading = false
      })

      return {
        ...toRefs(state),
        formatToPrice,
      };
    },
    data() {
      return {
        notification: {},
      }
    },

    mounted() {
      this.getSummaryDeposit();
    },

    computed: {
      ...mapGetters({ deposit_balance: 'getDepositBalance' }),
    },

    methods: {
      ...mapActions({ getSummaryDeposit: 'getSummaryDeposit' }),
      checkout(id){
        // console.log(id)
        const params = {
          plan_id:id
        }
        this.axios.post(`v1/subscription`,params)
        .then((res) => {
          // console.log('resp',res)
          let url = res.data.data.invoice_url;
          window.open(url);
          // if(res.data.code === 200){            
          //   // setTimeout(() => {
          //   // let message = res ? res.data.msg : res.msg;
          //   //   this.notification = {
          //   //     state: true,
          //   //     message,
          //   //   };
          //   //   this.$router.push({ name: 'dashboard' })
          //   // }, 4000);
          // }else{
          //   let message = res ? res.data.msg : res.msg;
          //   this.notification = {
          //     state: true,
          //     message,
          //   };
          // }

        })
        .catch((error) => {
          // console.log('resp',error.response)

          this.submitting=false;
            let message = error.response ? error.response.data.message : error.msg;
            this.notification = {
              state: true,
              message,
            };
          })
      }
    },
  };
</script>

<style lang="scss" scoped>
  p {
    margin: 0;
    padding: 0;
  }
  .pricing-wrapper {
    padding: 0 10px;
    margin: 40px 0;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @media (min-width: 768px) {
      gap: 43px;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  .pricing {
    &-header {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
    }
    &-card {
      border-radius: 12px;
      text-align: center;
      padding: 10px;
      border: solid 1px #e0e0e0 !important;
      &-header {
        display: flex;
        justify-content: center;
        place-items: center;
        padding: 20px 0;
        padding-top:10;
        &__img {
          margin-right: 0.5rem;
        }
        &__title {
          font-size: 1rem;
        }
        &__price {
          font-size: 0.5rem;
          font-weight: bold;
        }
      }
      &-feature {
        &__poin {
          color: #4C6583;
          font-weight: bold;
        }
        & span {
          text-transform: uppercase;
        }
      }
      &-action {
        padding: 20px 0;
        flex-direction: column;
        &__button {
          height: 44px !important;
          border-radius: 6px;
        }
      }
    }
  }
</style>